import { AxiosError } from 'axios';
import { defineStore, StoreDefinition } from 'pinia';
import { Ref, ref } from 'vue';

import { Thread } from '@/models/Threads';
import { sa_api } from '@/services/api/api';

export const useThreadsStore: StoreDefinition = defineStore('threads', () => {
  const threads: Ref<Thread[]> = ref([]);

  const fetchThreads = async (): Promise<void> => {
    await sa_api
      .post('/chat/threads', { input: {} })
      .then(({ data: { output } }) => {
        threads.value = output.threads;
      })
      .catch((error: AxiosError) => {
        console.error(error);
        threads.value = [];
      });
  };

  const addThread = (thread: Thread) => {
    threads.value.push(thread);
  };

  const updateThread = (updatedThreadId: string, updatedAt: number) => {
    const threadToUpdate = threads.value.find((thread) => thread.thread_uuid === updatedThreadId);
    if (threadToUpdate) threadToUpdate.updated_at = updatedAt;
  };

  return {
    history,
    threads,
    addThread,
    fetchThreads,
    updateThread
  };
});
