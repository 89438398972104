import { Router } from 'vue-router';

import AuthService from '@/services/auth/authService';
import { isTokenExpired } from '@/services/auth/jwt/validator';
export class jwtQueryParser {
  private router: Router;
  private error: string | null = null;

  constructor(router: Router) {
    this.router = router;
    this.getJwtTokens();
  }

  private getJwtTokens(): void {
    const newQuery = this.router.currentRoute.value.query;
    const jwt = newQuery['access-token'];
    const refreshToken = newQuery['refresh-token'];

    if (jwt && refreshToken && !isTokenExpired(jwt as string) && !isTokenExpired(refreshToken as string)) {
      this.handleAccessToken(jwt as string);
      this.handleRefreshToken(refreshToken as string);
    } else {
      this.setError();
    }
  }

  private handleAccessToken(token: string): void {
    AuthService.storeAccessToken(token);
  }

  private handleRefreshToken(token: string): void {
    AuthService.storeRefreshToken(token);
  }

  private setError(): void {
    this.error = 'Invalid token';
  }

  public getError(): string | null {
    return this.error;
  }
}
