export default {
  title: 'Er is een fout opgetreden',

  '400': 'Ongeldig Verzoek - De server kan het verzoek niet verwerken.',
  '401': 'Niet Geautoriseerd - Authenticatie is vereist en heeft gefaald of is nog niet verschaft.',
  '402': 'Betaling Vereist - Gereserveerd voor toekomstig gebruik.',
  '403': 'Verboden - De server weigert actie uit te voeren.',
  '404': 'Niet Gevonden - De server kan de gevraagde bron niet vinden.',
  '405': 'Methode Niet Toegestaan - De methode is bekend maar is verboden.',
  '406': 'Niet Acceptabel - De server kan geen content vinden die voldoet aan de criteria.',
  '407': 'Proxy Authenticatie Vereist - Authenticatie met de proxy is vereist.',
  '408': 'Verzoek Timeout - Het verzoek duurde te lang voor de server.',
  '409': 'Conflict - Het verzoek conflicteert met de huidige staat van de server.',
  '410': 'Verdwenen - De bron is permanent verwijderd en niet meer beschikbaar.',
  '411': 'Lengte Vereist - De "Content-Length" is niet gedefinieerd en de server vereist dit.',
  '412': 'Voorwaarde Mislukt - Een of meerdere voorwaarden in de verzoekheader zijn mislukt.',
  '413': 'Inhoud Te Groot - Het verzoek is te groot voor de server om te verwerken.',
  '414': 'URI Te Lang - De URI van het verzoek is te lang voor de server.',
  '415': 'Mediatype Niet Ondersteund - Het verzoek mediatype wordt niet ondersteund door de server.',
  '416': 'Bereik Niet Voldaan - Het gespecificeerde bereik is niet te voldoen.',
  '417': 'Verwachting Mislukt - De server kan niet voldoen aan de verwachtingen van het verzoek.',
  '418': 'Ik ben een theepot - Een paas-ei statuscode gedefinieerd in RFC 2324.',
  '421': 'Verzoek Misleid - Het verzoek werd gericht naar een server die niet kan produceren.',
  '422': 'Niet Verwerkbaar Entiteit - Het verzoek was goed gevormd maar kon niet worden gevolgd.',
  '423': 'Vergrendeld - De bron die wordt geopereerd is vergrendeld.',
  '424': 'Afhankelijkheid Mislukt - Het verzoek faalde vanwege falen van een vorig verzoek.',
  '425': 'Te Vroeg - Het risico dat het vroeg verwerken van het verzoek.',
  '426': 'Upgrade Vereist - De client moet switchen naar een andere protocol.',
  '428': 'Voorwaarde Vereist - De server vereist dat het verzoek voorwaardelijk is.',
  '429': 'Te Veel Verzoeken - De gebruiker heeft te veel verzoeken in een bepaalde tijd.',
  '431': 'Verzoekkopvelden Te Groot - De server weigert het verzoek omdat de kopvelden te groot zijn.',
  '451': 'Niet Beschikbaar Om Juridische Redenen - De toegang tot de bron is juridisch geblokkeerd.',
  '500': 'Interne Serverfout - De server stuitte op een onverwachte conditie.',
  '501': 'Niet Geïmplementeerd - De server ondersteunt de functionaliteit niet om het verzoek te vervullen.',
  '502': 'Slechte Gateway - De server kreeg een ongeldig antwoord van de upstream server.',
  '503': 'Dienst Niet Beschikbaar - De server is momenteel niet beschikbaar (overbelast of down).',
  '504': 'Gateway Timeout - De upstream server faalde om tijdig te antwoorden.',
  '505': 'HTTP Versie Niet Ondersteund - De server ondersteunt de HTTP versie niet.',
  '506': 'Variant Ook Onderhandelt - Transparante content onderhandeling voor de aanvraag resulteert in een cirkel.',
  '507': 'Onvoldoende Opslag - De server kan het verzoek niet verwerken door onvoldoende opslag.',
  '508': 'Lus Gedetecteerd - De server detecteerde een oneindige lus tijdens het verwerken van het verzoek.',
  '510': 'Niet Uitgebreid - Verdere extensies zijn nodig voor het verzoek.',
  '511': 'Netwerkauthenticatie Vereist - De client moet zich authenticeren om netwerktoegang te krijgen.',

  unknown: 'Er is een onbekende fout opgetreden. Probeer het later opnieuw.'
};
