export default {
  title1: 'Over deze bèta',
  p1: `De Schulinck Assistent is een tool die met behulp van <i>Artificial Intelligence</i> (AI) informatie genereert. De Schulinck Assistent maakt uitsluitend gebruik van informatie uit de Schulinck kennisbanken. Het is belangrijk om te weten dat de Schulinck Assistent momenteel in de bètafase verkeert.
        <br /><br />Wat betekent dit precies? We leggen het graag uit.<br /><br />
        Heb je vragen of feedback? Neem dan contact op via <a href="mailto:product.schulinck@wolterskluwer.com">product.schulinck@wolterskluwer.com</a><br /><br /><br />`,
  title2: `De vaardigheden en beperkingen van de Schulinck Assistent`,
  p2: `De Schulinck Assistent is slim en beschikt over alle informatie uit de Schulinck kennisbanken. De Schulinck Assistent is in staat om complexe vragen snel te beantwoorden.<br /><br />

        Helaas vertaalt die snelheid en toegang tot informatie zich niet altijd naar gezond verstand.<br /><br />

        De Schulinck Assistent mist enkele cognitieve vaardigheden, waaronder:
        <ul>
        <li>Gevoel of bewustzijn</li>
        <li>Emotioneel begrip</li>
        <li>Contextueel bewustzijn</li>
        </ul><br />`,

  title3: 'Dubbelcheck resultaten en informatie',
  p3: `Je kunt Schulinck Assistent om advies en begeleiding vragen, accepteer geen informatie die je niet kunt verifiëren of als waar kunt herkennen.<br /><br />
        Wees ervan bewust dat het veel moeilijker is om fouten en incorrecte adviezen op te merken als je minder ervaring hebt in het vakdomein. De Assistent kan elementen verkeerd interpreteren.<br /><br /><br />`,
  title4: 'Gebruik geen persoonsgegevens',
  p4: `Wij zetten ons in voor de beveiliging en privacy van je gegevens bij het gebruik van (Generatieve) AI-toepassingen. Alle gegevensverwerking en opslag voldoen aan strenge EU-regelgeving om je informatie te beschermen.<br /><br /><br />`,
  title5: 'Wat is een bètaversie?',
  p5: `Een bètaversie van een product, in dit geval de Schulinck Assistent, is een vroege versie die beschikbaar wordt gesteld aan een beperkte groep gebruikers voor testdoeleinden. In de bètafase wordt Schulinck Assistent getest door een geselecteerde groep gebruikers om potentiële fouten te vinden voordat de definitieve versie wordt gelanceerd.<br /><br />`,
  title6: 'Wat dit voor jou betekent',
  p6: `<ol>
      <li><strong>Onvolledige of incorrecte informatie:</strong> De door Schulinck Assistent gegenereerde informatie kan onvolledig of incorrect zijn. Hoewel we ons best doen om nauwkeurige en betrouwbare informatie te leveren, is de tool nog in ontwikkeling en kunnen er fouten optreden.</li>
      <li><strong>Feedback en verbeteringen:</strong> Jouw feedback is van onschatbare waarde. We moedigen je aan om onjuistheden, fouten of suggesties voor verbeteringen met ons te delen. Dit helpt ons om de Schulinck Assistent te verbeteren.</li>
      <li><strong>Opslag van vragen en antwoorden:</strong> Om het product verder te verbeteren slaan we alle vragen en antwoorden die gesteld worden op.</li>
      </ol>`,
  title7: 'Aansprakelijkheid Wolters Kluwer',
  p7: `Hoewel de Schulinck Assistent waardevolle inzichten en samenvattingen biedt, aanvaardt Wolters Kluwer geen aansprakelijkheid voor de inhoud van de gegenereerde uitkomsten en is het geen vervanging voor professioneel juridisch advies. Gebruikers moeten de informatie verkregen via de Schulinck Assistent onafhankelijk verifiëren voordat ze deze gebruiken voor juridische beslissingen of werkzaamheden.<br /><br />
        Het is niet toegestaan om content van Wolters Kluwer en gegenereerde uitkomsten van de Schulinck Assistent te gebruiken (als trainingsmateriaal of anderszins) voor eigen AI-applicaties die buiten het beheer van Wolters Kluwer vallen. Tekst- en datamining is niet toegestaan.<br /><br />
        Voor meer informatie zie ook: <a href="https://www.wolterskluwer.com/nl-nl/solutions/nederland/disclaimer/generatieve-ai-disclaimer">Generatieve AI Disclaimer</a><br /><br />`,
  version: 'Schulinck Assistent versie %version%'
};
