<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { type ComponentPublicInstance, ref } from 'vue';

import { Source } from '@/models/Message';
import { useLocaleTranslator } from '@/translations';

interface Props {
  source: Source;
}

defineProps<Props>();

const { translate } = useLocaleTranslator();

const element = ref<ComponentPublicInstance>();
</script>
<template>
  <div>
    <Stack ref="element" :justifyContent="Position.Between" class="source-block" :gap="0">
      <p class="title">{{ source.title }}</p>
      <p class="type">{{ translate(`contentType.${source.content_type}`) }}</p>
    </Stack>
  </div>
</template>

<style scoped lang="scss">
.source-block {
  padding: 0.5rem;
  background-color: $wk-white;
  border-radius: 0.5rem;
  border: solid 1px $wk-gray-tint4;
  cursor: pointer;
  transition: all 0.2s;
  height: 4rem;

  @include mq(xs) {
    height: 5rem;
  }

  &:hover {
    border: solid 1px $wk-blue-shade1;
    box-shadow: 0 0.25rem 0.75rem 0 rgba($wk-black, 0.1);
  }

  .title {
    position: relative;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.3rem;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .type {
    font-size: 0.6875rem;
    line-height: 1.125rem;
    color: $wk-gray-tint1;
  }
}
</style>
