<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import { Position } from '@schulinck/components/theme/position';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import Link from '@schulinck/components/ui/link/Link.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import { Breadcrumb, breadcrumbsIcons } from '@/models/Breadcrumbs';
import { ContentType } from '@/models/Message';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

interface Props {
  contentType: ContentType;
  breadcrumbs?: Breadcrumb[] | null;
}

defineProps<Props>();
</script>

<template>
  <Cluster :wrap="true" :alignItems="Position.Center" :gap="0.2" class="type">
    <Cluster :alignItems="Position.Center" :gap="0.2" class="content-type">
      <Icon :name="breadcrumbsIcons[contentType]" />
      <Typography :variant="TypographyVariants.Paragraph" noMargin>
        {{ translate(`contentType.${contentType}`) }}
      </Typography>
      <Icon v-if="breadcrumbs && breadcrumbs.length > 0" :name="IconNames.ChevronRight" :size="0.75" />
    </Cluster>
    <Cluster
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="breadcrumb.title"
      :alignItems="Position.Center"
      :gap="0.2"
    >
      <Link target="_blank" :inherit="true" :href="breadcrumb.permalink">{{ breadcrumb.title }}</Link>
      <Icon v-if="index !== breadcrumbs.length - 1" :name="IconNames.ChevronRight" :size="0.75" />
    </Cluster>
  </Cluster>
</template>

<style scoped lang="scss">
.content-type {
  cursor: auto;
}

.type {
  margin-top: 0;
  font-size: 0.8125rem;
  line-height: 1.625rem;
  color: $wk-gray-tint1;

  .wk-icon {
    margin: 0;
  }
}
</style>
