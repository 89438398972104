import { createGtm } from '@gtm-support/vue-gtm';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import { createApp, nextTick } from 'vue';
import VueGtag from 'vue-gtag';

import PermissionPlugin from '@/plugins/permissions';
import { useLocaleTranslator } from '@/translations';

import App from './App.vue';
import router from './router';

const { translate } = useLocaleTranslator();
const DEFAULT_TITLE: string = translate('application.title');

const app = createApp(App);

router.afterEach((to) => {
  nextTick(() => {
    document.title = typeof to.meta.title === 'string' ? to.meta.title : DEFAULT_TITLE;
  });
});

if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'dev') {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', import.meta.env.VITE_APP_URL],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

app
  .use(createPinia())
  .use(router)
  .use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID || 'GTM-XXXXXX',
      vueRouter: router
    })
  )
  .use(VueGtag, {
    appName: DEFAULT_TITLE,
    config: {
      id: import.meta.env.VITE_GA_ID,
      params: {
        anonymize_ip: true
      }
    }
  })
  .use(PermissionPlugin)
  .mount('#app');
