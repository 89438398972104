import Organisation from '@/models/Organisation';
import Product from '@/models/Product';

const emptyProfileMenu = {
  menu: {
    _embedded: {
      menuItems: [
        {
          name: 'mySubscriptions',
          title: '',
          icon: undefined,
          _links: {
            target: {
              href: '#',
              attributes: {
                target: '_self'
              }
            }
          },
          _embedded: {
            menuItems: [{}]
          }
        },
        {
          name: 'logout',
          title: '',
          icon: undefined,
          _links: {
            target: {
              href: import.meta.env.VITE_KB_LOGOUT_URL,
              attributes: {
                target: '_self'
              }
            }
          },
          _embedded: {
            menuItems: []
          }
        }
      ]
    }
  }
};

export const createProfileMenuContents = (organisations: Organisation[], products: Product[]) => {
  const profileMenu = emptyProfileMenu;

  profileMenu.menu._embedded.menuItems.forEach((menuItem) => {
    if (menuItem.name === 'mySubscriptions') {
      menuItem._embedded.menuItems = products.map((product) => {
        return {
          name: '',
          title: product.title,
          icon: undefined,
          _links: { target: { href: product.url, attributes: { target: '_blank' } } },
          _embedded: { menuItems: [] }
        };
      });
    }
  });

  return profileMenu;
};
