import Resource from '@schulinck/lib/hateoas/resources/Resource';

export interface UserDetailsDataInterface {
  id: string;
  salutation: string;
  initials: string;
  firstName: string;
  lastName: string;
  job: string;
  jobArea: string;
  lastLoginDate: string | null;
  lastLoggedInKnowledgeBaseOrganisationId: string | null;
  lastLoggedInKnowledgeBaseProductCode: string | null;
}

export default class UserDetails extends Resource {
  public readonly id: string;
  public readonly salutation: string;
  public readonly initials: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly job: string;
  public readonly jobArea: string;
  public readonly lastLoginDate: string | null;
  public readonly lastLoggedInKnowledgeBaseOrganisationId: string | null;
  public readonly lastLoggedInKnowledgeBaseProductCode: string | null;

  constructor(data: UserDetailsDataInterface) {
    super(data);

    this.id = data.id;
    this.salutation = data.salutation;
    this.initials = data.initials;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.job = data.job;
    this.jobArea = data.jobArea;
    this.lastLoginDate = data.lastLoginDate;
    this.lastLoggedInKnowledgeBaseOrganisationId = data.lastLoggedInKnowledgeBaseOrganisationId;
    this.lastLoggedInKnowledgeBaseProductCode = data.lastLoggedInKnowledgeBaseProductCode;
  }
}
