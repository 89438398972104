import Resource, { HALResourceDataInterface } from '@schulinck/lib/hateoas/resources/Resource';

export interface PermissionSetDataInterface extends HALResourceDataInterface {
  threadReadUser: boolean;
  threadCreateUser: boolean;
  threadDeleteUser: boolean;
  sourceFeedback: boolean;
  answerFeedback: boolean;
}

export enum Permission {
  ThreadReadUser = 'threadReadUser',
  ThreadCreateUser = 'threadCreateUser',
  ThreadDeleteUser = 'threadDeleteUser',
  SourceFeedback = 'sourceFeedback',
  AnswerFeedback = 'answerFeedback'
}

export default class PermissionSet extends Resource {
  public readonly threadReadUser: boolean;
  public readonly threadCreateUser: boolean;
  public readonly threadDeleteUser: boolean;
  public readonly sourceFeedback: boolean;
  public readonly answerFeedback: boolean;

  constructor(data: PermissionSetDataInterface) {
    super(data);

    this.threadReadUser = data.threadReadUser || false;
    this.threadCreateUser = data.threadCreateUser || false;
    this.threadDeleteUser = data.threadDeleteUser || false;
    this.sourceFeedback = data.sourceFeedback || false;
    this.answerFeedback = data.answerFeedback || false;
  }

  public can(permission: Permission | Permission[]): boolean {
    if (Array.isArray(permission)) {
      return permission.every((p) => this.checkPermission(p));
    } else {
      return this.checkPermission(permission);
    }
  }

  private checkPermission(permission: Permission): boolean {
    switch (permission) {
      case Permission.ThreadReadUser:
        return this.threadReadUser;
      case Permission.ThreadCreateUser:
        return this.threadCreateUser;
      case Permission.ThreadDeleteUser:
        return this.threadDeleteUser;
      case Permission.SourceFeedback:
        return this.sourceFeedback;
      case Permission.AnswerFeedback:
        return this.answerFeedback;
      default:
        return false;
    }
  }

  public hasNoPermissions(): boolean {
    return !this.threadCreateUser && !this.threadReadUser && !this.threadDeleteUser;
  }
}
