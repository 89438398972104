<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  duration: number;
  mode?: 'default' | 'in-out' | 'out-in';
}
const props = defineProps<Props>();
const durationString = computed(() => `${props.duration}ms`);
</script>
<template>
  <transition name="fade" :mode="mode">
    <slot />
  </transition>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all v-bind(durationString) ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
