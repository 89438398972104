<script async setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';

import { Breakpoint } from '@/models/Breakpoint';
import { Thread } from '@/models/Threads';
import { useThreadStore } from '@/stores/thread';
import { useThreadsStore } from '@/stores/threads';
import { useLocaleTranslator } from '@/translations';

import NotFound from '../NotFound.vue';
import FilterInputField from './FilterInputField.vue';

interface Props {
  show: boolean;
  toggleSidebar: () => void;
}
const props = defineProps<Props>();

const { translate } = useLocaleTranslator();
const threadsStore = useThreadsStore();
const threadStore = useThreadStore();
const filterQuery = ref('');
const format: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
const activeThreadId = computed(() => threadStore.uuid);

const formatDate = (date: Date) => {
  return date.toLocaleDateString(translate('date.locale_key'), format);
};

const sortedThreads: ComputedRef<Thread[] | null> = computed(() => {
  const query = filterQuery.value.toLowerCase();
  return [...threadsStore.threads]
    .filter((t: Thread) => t.title.toLowerCase().includes(query))
    .sort((a: Thread, b: Thread) => (b.updated_at <= a.updated_at ? -1 : 1))
    .map((thread: Thread) => ({ ...thread, updated_at: formatDate(new Date((thread.updated_at as number) * 1000)) }));
});

const groupedThreads = computed<Partial<Record<string, Thread[]>> | null>(
  () => sortedThreads.value && Object.groupBy(sortedThreads.value, ({ updated_at }) => updated_at)
);

const checkDate = (date: string) => {
  const today = new Date();
  if (date === formatDate(today)) return translate('sidebar.headers.today');

  const yesterday = new Date(today.setDate(today.getDate() - 1));
  if (date === formatDate(yesterday)) return translate('sidebar.headers.yesterday');

  return date;
};

const updateFilter = (value: string) => (filterQuery.value = value);
const threadClicked = (thread: Thread) => {
  if (thread.thread_uuid !== activeThreadId.value) threadStore.openThread(thread.thread_uuid);
  if (window.innerWidth < Breakpoint.Xs) {
    props.toggleSidebar();
  }
};

onMounted(async () => await threadsStore.fetchThreads());
</script>

<template>
  <Stack :class="['threadsList', show && 'visible']" :gap="1.5">
    <FilterInputField :query="filterQuery" :onUpdate="updateFilter" />
    <Stack class="list">
      <TransitionGroup name="list">
        <div v-for="(threadsGroup, updated_at) in groupedThreads" :key="updated_at" class="group">
          <p class="threadsGroupHeader">{{ checkDate(updated_at) }}</p>
          <Stack :gap="0">
            <p
              v-for="thread in threadsGroup"
              :key="thread.thread_uuid"
              :class="['thread', activeThreadId === thread.thread_uuid && 'active']"
            >
              <Clickable @click="threadClicked(thread)">{{ thread.title }}</Clickable>
            </p>
          </Stack>
        </div>
      </TransitionGroup>
      <NotFound v-if="sortedThreads?.length === 0" :title="translate('sidebar.filter.noResults')" />
    </Stack>
  </Stack>
</template>

<style scoped lang="scss">
.threadsList {
  width: 100%;
  overflow: auto;
  padding-top: 1rem;
  transition: all 1s ease-in-out;

  .list {
    max-height: 100%;
    overflow: auto;
    width: 100%;
    padding-bottom: 0.5rem;
    border: solid 1px transparent; // prevent layout jump when opening
    opacity: 0;
    transition: opacity 0.1s ease-in-out 0s;

    .wk-not-found {
      width: 40%;
      align-self: center;
      margin-top: 2rem;

      :deep(.wk-typography-h4) {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }

  .filter {
    margin-left: 0.75rem;
  }

  .thread {
    display: inline-block;
    color: $wk-gray-shade1;
    padding: 0.375rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.875rem;
    transition: all 300ms ease-in-out;
    opacity: 1;

    &:hover,
    &.active {
      color: $wk-blue-shade1;
      background-color: $wk-blue-tint6;
    }

    &.active {
      font-weight: 500;

      .wk-clickable {
        cursor: default;
      }
    }

    .wk-clickable {
      width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      transition: width 0.5s ease-in-out;
    }
  }

  .threadsGroupHeader {
    color: $wk-gray-tint1;
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem 0 0.75rem;
  }

  &.visible {
    .filter,
    .list {
      width: 100%;
      opacity: 1;
      transition: opacity 0.3s ease-in-out 0.3s;
    }

    .filter {
      width: calc(100% - 1.5rem);
    }

    .wk-clickable {
      width: 100%;
    }
  }
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 1s ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.wk-tooltip {
  max-width: unset !important;
}
</style>
