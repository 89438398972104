<script setup lang="ts">
import Container from '@schulinck/components/layout/container/Container.vue';
import { computed } from 'vue';

import { useLocaleTranslator } from '@/translations';

import FooterLogo from './FooterLogo.vue';

const { translate } = useLocaleTranslator();
const year = computed(() => new Date().getFullYear().toString());
</script>

<template>
  <div class="footer">
    <Container class="footer-container">
      <div class="footer-content">
        <FooterLogo link="https://www.wolterskluwer.com/nl-nl" />

        <a href="https://www.wolterskluwer.com/nl-nl/privacy-cookies">{{ translate('footer.privacy') }}</a>
        <router-link :to="{ name: 'cookie-policy' }" target="_blank">
          {{ translate('footer.cookiePolicy') }}
        </router-link>
        <a href="https://www.wolterskluwer.com/nl-nl/solutions/nederland/gdpr">{{ translate('footer.gdpr') }}</a>
        <a href="https://www.wolterskluwer.com/nl-nl/solutions/nederland/disclaimer">{{
          translate('footer.disclaimer')
        }}</a>
        <a href="https://www.wolterskluwer.com/nl-nl/solutions/nederland/algemene-voorwaarden">{{
          translate('footer.conditions')
        }}</a>
      </div>

      <div class="footer-copyright">{{ translate('footer.copyright', { year }) }}</div>
    </Container>
  </div>
</template>

<style scoped lang="scss">
.footer {
  background-color: $wk-white;
  font-size: 0.85rem;
  font-weight: 300;
}

.footer-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.9375rem 2rem;
  gap: 1rem;

  @include mq(md) {
    flex-direction: row;
    align-items: center;
  }
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @include mq(sm) {
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
  }

  a {
    padding: 2px;
  }
}
</style>
