<script setup lang="ts">
import banana from '@/assets/banana-large.svg';
import Error from '@/components/layout/Error.vue';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();
</script>

<template>
  <Error :image="banana" :title="translate('403.title')" />
</template>
