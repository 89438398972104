<script setup lang="ts">
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { computed, ref, watch } from 'vue';

import { EventNoData, EventType } from '@/models/Event';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useThreadStore } from '@/stores/thread';
import FadeTransition from '@/transitions/FadeTransition.vue';
import { useLocaleTranslator } from '@/translations';

import GrowingTextarea from './GrowingTextarea.vue';

const { translate } = useLocaleTranslator();
const threadStore = useThreadStore();

const maxPromptLength = 2000;
const inputValue = ref<string>('');
const inputElement = ref<HTMLElement>();
const promptLengthExceededEvent: Event<EventNoData> = new Event().setType(EventType.PromptLengthExceeded);

defineExpose({ inputElement });
const isResponseLoading = computed(() => threadStore.loading);
const promptLengthExceeded = computed(() => inputValue.value.length > maxPromptLength);
const preventSubmit = computed(() => inputValue.value.length === 0 || promptLengthExceeded.value);

const handleInputChange = (value: string) => (inputValue.value = value);
const submitPrompt = (event: KeyboardEvent) => {
  event.preventDefault();
  if (!isResponseLoading.value && !preventSubmit.value) {
    threadStore.addQuestion(inputValue.value, null);
    inputValue.value = '';
  }
};

watch(promptLengthExceeded, () => dataLayer.push(promptLengthExceededEvent));
</script>

<template>
  <div class="small-prompt">
    <GrowingTextarea
      :inputValue="inputValue"
      :placeholder="translate('small-prompt.placeholder')"
      :onUpdate="handleInputChange"
      :onSubmit="submitPrompt"
    />
    <FadeTransition :duration="350">
      <button v-if="isResponseLoading" type="submit">
        <Icon :name="IconNames.Spinner" spin />
      </button>
      <button v-else type="submit" :disabled="preventSubmit" :onclick="submitPrompt">
        <Icon :name="IconNames.ArrowUp" />
      </button>
    </FadeTransition>
  </div>
</template>

<style scoped lang="scss">
.small-prompt {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: $wk-white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  outline: solid 1px $wk-gray-tint1;
  padding-right: calc(33px + 2rem);
  position: relative;

  &:focus-within {
    outline: solid 2px $wk-black;
  }

  .small-prompt-input {
    width: 100%;
    max-height: 4.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    color: rgba(18, 18, 18, 0.38);
    line-break: anywhere;

    &:focus {
      outline: none;
    }

    &.focussed,
    &.dirty {
      color: $wk-gray-shade2;
    }
  }

  button[type='submit'] {
    background: $wk-blue;
    color: $wk-white;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    margin-left: 1rem;
    font-size: 1.0625rem;
    position: absolute;
    right: 1rem;
    bottom: 0.8125rem;

    &:disabled {
      background: $wk-gray-tint5;
    }
  }
}
</style>
<style lang="scss">
.wk-dropdown {
  margin-top: 0.5rem;
  border-top-width: 1px !important;
}
</style>
