import { onMounted, Ref } from 'vue';

export const handlePaste = (event: ClipboardEvent) => {
  event.preventDefault();
  const text = event.clipboardData?.getData('text/plain') || '';
  document.execCommand('insertText', false, text);
};

export const handleDragOver = (event: MouseEvent) => {
  event.preventDefault();
};

export const handleDrop = (event: DragEvent) => {
  event.preventDefault();
};

export const setupDragAndDropHandlers = (inputElement: Ref<HTMLElement | undefined>) => {
  onMounted(() => {
    inputElement.value?.addEventListener('paste', handlePaste);
    inputElement.value?.addEventListener('dragover', handleDragOver);
    inputElement.value?.addEventListener('drop', handleDrop);
  });
};
