import { addPrefixToKeys, getPrefixFromFileName } from '@/services/utils/translation';

import about from './about';
import action from './action';
import cookie from './cookie';
import date from './date';
import error from './error';
import feedback from './feedback';
import general from './general';
import loginPopup from './loginPopup';
import profileMenu from './profileMenu';
import sidebar from './sidebar';

const files = {
  about,
  action,
  cookie,
  date,
  error,
  feedback,
  loginPopup,
  profileMenu,
  sidebar
};

const translationsWithPrefix: NonNullable<unknown> = Object.entries(files).reduce(
  (acc: NonNullable<unknown>, [fileName, fileContent]) => {
    const prefix: string = getPrefixFromFileName(fileName);
    const prefixedTranslations: Record<string, unknown> = addPrefixToKeys(
      fileContent as Record<string, unknown>,
      prefix
    );
    return { ...acc, ...prefixedTranslations };
  },
  {}
);

export default {
  ...translationsWithPrefix,
  ...general
};
