import { defineStore } from 'pinia';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { EventData, EventMetaDefaultData } from '@/models/Event';
import { useFilterStore } from '@/stores/tracking/filter';
import { useUserStore } from '@/stores/user';

export const useEventMetaDataStore = defineStore('eventMetaData', () => {
  const userStore = useUserStore();
  const additionalData: Ref<EventData> = ref<EventData>({});
  const userUuid: ComputedRef<string | undefined> = computed<string | undefined>(() => userStore.userDetails?.id);
  const organisationId: ComputedRef<string | undefined> = computed<string | undefined>(
    () => userStore.selectedOrganisation?.id
  );
  const domainCode: ComputedRef<string | undefined> = computed<string | undefined>(
    () => userStore.lastChosenDomain?.productCode
  );
  const unixTimeStamp: Ref<number> = ref<number>(Date.now());

  const add = (data: EventData): void => {
    additionalData.value = { ...additionalData.value, ...data };
  };

  const defaultData: ComputedRef<EventMetaDefaultData> = computed<EventMetaDefaultData>(() => ({
    user_uuid: userUuid.value,
    organisation_id: organisationId.value,
    domain_code: domainCode.value,
    unix_timestamp: unixTimeStamp.value
  }));

  const getData: ComputedRef<EventMetaDefaultData & EventData> = computed<EventMetaDefaultData & EventData>(() => ({
    ...defaultData.value,
    ...additionalData.value,
    ...useFilterStore().getFilters
  }));

  return {
    additionalData,
    userUuid,
    organisationId,
    domainCode,
    unixTimeStamp,
    add,
    defaultData,
    getData
  };
});
