import { App } from 'vue';

import { Permission } from '@/models/PermissionSet';
import { useUserStore } from '@/stores/user';

declare module 'vue' {
  interface ComponentCustomProperties {
    $hasPermission(permission: Permission | Permission[]): boolean;
  }
}

export default {
  install: (app: App) => {
    const userStore = useUserStore();

    app.config.globalProperties.$hasPermission = (permission: Permission | Permission[]): boolean => {
      if (userStore.permissions === null) {
        return false;
      }

      return userStore.permissions.can(permission);
    };
  }
};
