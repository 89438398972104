<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { onMounted } from 'vue';

import router from '@/router';
import AuthService from '@/services/auth/authService';
import { jwtQueryParser } from '@/services/auth/jwtQueryParser';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();
const userStore = useUserStore();

onMounted(() => {
  const init = async () => {
    const urlparser = new jwtQueryParser(router);
    if (urlparser.getError()) {
      await router.push({ name: 'unauthorized' });
    } else {
      await userStore.init();

      if (userStore.permissions?.hasNoPermissions() ?? true) {
        await router.push({ name: 'unauthorized' });
        return;
      }

      const destinationUrl = AuthService.getDestinationUrl() || null;

      if (destinationUrl !== null) {
        AuthService.removeDestinationUrl();

        await router.push({ path: destinationUrl });
        return;
      }

      await router.push({ name: 'home' });
    }
  };
  init();
});
</script>

<template>
  <Stack :alignItems="Position.Center" class="initiating">
    <Typography :variant="TypographyVariants.Paragraph">
      {{ translate('auth.initiating') }}
    </Typography>
  </Stack>
</template>

<style scoped lang="scss">
.initiating {
  margin: 1rem;
  padding-top: 10vh;
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.4;
  }
}
</style>
