export default {
  title: 'Cookiebeleid',
  p1: ` Wij gebruiken cookies om onze websites te optimaliseren en het verkeer op onze website te analyseren. Als we van
        tevoren uw geïnformeerde toestemming hebben verkregen, kunnen we soms gebruik maken van cookies van derden, met
        name plugins voor sociale media, om u in staat te stellen ideeën en informatie met betrekking tot ons
        onmiddellijk en voor onze eigen marketingdoeleinden te delen.
        <br /><br />
        Cookies of soortgelijke technieken (gezamenlijk "Cookies" genoemd) zijn kleine tekstbestanden of pixels die op
        uw computer of mobiele apparaat kunnen worden opgeslagen. Cookies kunnen nodig zijn om het browsen op de website
        te vergemakkelijken en gebruiksvriendelijker te maken. Cookies kunnen ook informatie verzamelen om persoonlijk
        browsegedrag te analyseren.
        <br /><br />
        We kunnen gebruik maken van cookies die behoren tot een van de vier onderstaande categorieën. Onze website
        cookiebanner maakt het u mogelijk om uw voorkeuren voor de niet-verplichte cookiecategorieën te beheren. De
        banner geeft ook een overzicht van alle gebruikte cookies onder de categorieën.
        <br /><br />`,
  cookie_types_title: `Soorten cookies`,
  type1: `Strikt noodzakelijke cookies - Deze cookies zijn noodzakelijk voor het functioneren van de website. Ze worden
          doorgaans ingesteld naar aanleiding van handelingen van uw kant die een verzoek om diensten betreffen, zoals
          het instellen van uw privacyvoorkeuren, inloggen of het invullen van formulieren. U kunt uw browser zo
          instellen dat deze cookies worden geblokkeerd of dat u een waarschuwing ontvangt. Dit kan gevolgen hebben voor
          de correcte functionering van (delen van) de site.`,
  type2: `Functionele cookies - Deze cookies stellen de website in staat om een verbeterde functionaliteit,
          gebruikerservaring en personalisering te bieden, en kunnen worden ingesteld door ons of door externe providers
          wiens diensten we aan onze pagina's hebben toegevoegd. Als u deze cookies niet accepteert, werken sommige van
          deze diensten of alle diensten mogelijk niet correct.`,
  type3: `Prestatiecookies - deze cookies ondersteunen analytische diensten die ons helpen de functionaliteit en
          gebruikerservaring van onze website te verbeteren.`,
  type4: `Reclamecookies - deze cookies kunnen worden toegepast om inzichten te verzamelen om gepersonaliseerde inhoud
          en reclame op onze en andere websites te verstrekken.`,
  more_info: `Voor meer informatie over cookies en/of het wijzigen van uw cookie-instemmingsvoorkeuren, kunt u te allen tijde
        terecht bij het Privacy Preference Center (Privacyvoorkeurencentrum).`
};
