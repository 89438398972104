<script setup lang="ts">
import { useLocaleTranslator } from '@/translations';
const { translate } = useLocaleTranslator();
</script>
<template>
  <p class="disclaimer">
    <span v-html="translate('disclaimer.message')"></span>
    <router-link :to="{ name: 'about' }" target="_blank">{{ translate('disclaimer.linkText') }}</router-link>
  </p>
</template>
<style scoped lang="scss">
.disclaimer {
  color: $wk-gray-shade2;
  font-size: 0.75rem;
  line-height: 1.3125rem;

  a {
    margin-left: 1ch;
    color: $wk-blue-shade1;
  }
}
</style>
