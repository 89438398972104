<script setup lang="ts">
import { ref } from 'vue';

interface Props {
  loading: boolean;
}

const props = defineProps<Props>();

const svgElement = ref();
const animationDuration = 5000;
const cssAnimationSpeed = `${animationDuration * 0.25}ms`;

const triggerInterval = setInterval(() => {
  if (!props.loading) clearInterval(triggerInterval);
  svgElement.value?.classList.toggle('animating');
}, animationDuration);
</script>

<template>
  <svg
    ref="svgElement"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="['animating', loading && 'loading']"
  >
    <g class="first">
      <rect x="3.53" y="14.8201" width="5.64706" height="5.64706" fill="#007AC3" />
      <rect x="9.16998" y="14.8201" width="5.64706" height="5.64706" fill="#409BD2" />
      <rect x="14.82" y="14.8201" width="5.64706" height="5.64706" fill="#007AC3" />
      <rect x="3.10999" y="14.8201" width="5.64706" height="5.64706" fill="#007AC3" />
      <rect x="3.52802" y="14.8201" width="5.64706" height="5.64706" fill="#85BC20" />
      <rect x="9.16998" y="14.8201" width="5.64706" height="5.64706" fill="#A4CD58" />
      <rect x="14.82" y="14.8201" width="5.64706" height="5.64706" fill="#85BC20" />
      <rect x="15.4718" y="14.8201" width="5.64706" height="5.64706" fill="#007AC3" />
      <rect x="3.11145" y="9.17993" width="5.64706" height="5.64706" fill="#A6D1EA" />
      <rect x="3.52899" y="9.17651" width="5.64706" height="5.64706" fill="#D4E8B1" />
      <rect x="9.16998" y="9.17651" width="5.64706" height="5.64706" fill="#E5202E" />
      <rect x="14.82" y="9.17651" width="5.64706" height="5.64706" fill="#A4CD58" />
      <rect x="15.4718" y="9.17993" width="5.64706" height="5.64706" fill="#409BD2" />
      <rect x="3.11145" y="3.52881" width="5.64706" height="5.64706" fill="#409BD2" />
      <rect x="3.53021" y="3.52881" width="5.64706" height="5.64706" fill="#A4CD58" />
      <rect x="9.16998" y="3.52734" width="5.64706" height="5.64706" fill="#D4E8B1" />
      <rect x="14.82" y="3.52881" width="5.64706" height="5.64706" fill="#A4CD58" />
      <rect x="15.4718" y="3.52966" width="5.64706" height="5.64706" fill="#007AC3" />
      <rect x="3.52844" y="3.52966" width="5.64706" height="5.64706" fill="#409BD2" />
      <rect x="9.16998" y="3.52966" width="5.64706" height="5.64706" fill="#A6D1EA" />
      <rect x="14.82" y="3.52966" width="5.64706" height="5.64706" fill="#007AC3" />
    </g>
    <g clip-path="url(#clip0_9620_14055)">
      <circle cx="12" cy="12" r="12" fill="white" class="mask" />
      <g class="second">
        <rect opacity="0.2" x="3.53" y="20.4686" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect opacity="0.2" x="9.16998" y="20.4686" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect opacity="0.2" x="14.82" y="20.4686" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect opacity="0.2" x="-2.10999" y="14.8201" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect opacity="0.2" x="3.52802" y="14.824" width="5.64706" height="5.64706" fill="#85BC20" />
        <rect opacity="0.2" x="9.16998" y="14.8242" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect opacity="0.2" x="14.82" y="14.8235" width="5.64706" height="5.64706" fill="#85BC20" />
        <rect opacity="0.2" x="20.4718" y="14.8234" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect opacity="0.2" x="-2.11145" y="9.17993" width="5.64706" height="5.64706" fill="#A6D1EA" />
        <rect opacity="0.2" x="3.52899" y="9.17651" width="5.64706" height="5.64706" fill="#D4E8B1" />
        <rect opacity="0.2" x="9.16998" y="9.17651" width="5.64706" height="5.64706" fill="#E5202E" />
        <rect opacity="0.2" x="14.82" y="9.17651" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect opacity="0.2" x="20.4718" y="9.17993" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect opacity="0.2" x="-2.11145" y="3.52881" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect opacity="0.2" x="3.53021" y="3.52881" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect opacity="0.2" x="9.16998" y="3.52734" width="5.64706" height="5.64706" fill="#D4E8B1" />
        <rect opacity="0.2" x="14.82" y="3.52881" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect opacity="0.2" x="20.4718" y="3.52966" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect opacity="0.2" x="3.52844" y="-2.12744" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect opacity="0.2" x="9.16998" y="-2.12744" width="5.64706" height="5.64706" fill="#A6D1EA" />
        <rect opacity="0.2" x="14.82" y="-2.1167" width="5.64706" height="5.64706" fill="#007AC3" />
      </g>
      <g class="third">
        <rect x="3.53" y="20.4686" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect x="9.16998" y="20.4686" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect x="14.82" y="20.4686" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect x="-2.10999" y="14.8201" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect x="3.52802" y="14.824" width="5.64706" height="5.64706" fill="#85BC20" />
        <rect x="9.16998" y="14.8242" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect x="14.82" y="14.8235" width="5.64706" height="5.64706" fill="#85BC20" />
        <rect x="20.4718" y="14.8234" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect x="-2.11145" y="9.17993" width="5.64706" height="5.64706" fill="#A6D1EA" />
        <rect x="3.52899" y="9.17651" width="5.64706" height="5.64706" fill="#D4E8B1" />
        <rect x="9.16998" y="9.17651" width="5.64706" height="5.64706" fill="#E5202E" />
        <rect x="14.82" y="9.17651" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect x="20.4718" y="9.17993" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect x="-2.11145" y="3.52881" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect x="3.53021" y="3.52881" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect x="9.16998" y="3.52734" width="5.64706" height="5.64706" fill="#D4E8B1" />
        <rect x="14.82" y="3.52881" width="5.64706" height="5.64706" fill="#A4CD58" />
        <rect x="20.4718" y="3.52966" width="5.64706" height="5.64706" fill="#007AC3" />
        <rect x="3.52844" y="-2.12744" width="5.64706" height="5.64706" fill="#409BD2" />
        <rect x="9.16998" y="-2.12744" width="5.64706" height="5.64706" fill="#A6D1EA" />
        <rect x="14.82" y="-2.1167" width="5.64706" height="5.64706" fill="#007AC3" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_9620_14055">
        <rect width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">
svg.loading {
  g.third rect {
    transition: opacity 0.5s;
  }
  &.animating {
    g.first rect,
    g.third rect {
      opacity: 0;
      animation: longdrop v-bind(cssAnimationSpeed) linear 0s forwards;

      @for $i from 1 through 21 {
        &:nth-of-type(#{$i}) {
          animation-delay: 190ms * ($i + 4);
        }
      }
    }
  }
}

@keyframes longdrop {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }
  15%,
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
</style>
