<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { computed, onMounted } from 'vue';
import { onBeforeRouteLeave, RouteLocationNormalized, useRoute } from 'vue-router';

import Conversation from '@/components/conversation/Conversation.vue';
import LargePrompt from '@/components/conversation/prompt/LargePrompt.vue';
import Disclaimer from '@/components/Disclaimer.vue';
import Greeting from '@/components/introduction/Greeting.vue';
import FirstLoginPopup from '@/components/layout/FirstLoginPopup.vue';
import MainLayout from '@/components/layout/MainLayout.vue';
import { useThreadStore } from '@/stores/thread';
import FadeTransition from '@/transitions/FadeTransition.vue';

const thread = useThreadStore();

const handleComingFromConversation = (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  if (from.name === 'conversation' && to.name === 'home') {
    thread.resetThread();
  }
};

onBeforeRouteLeave((to, from, next) => {
  handleComingFromConversation(to, from);
  next();
});

onMounted(() => {
  const route = useRoute();
  const threadUuid = route.params.thread_uuid;
  if (threadUuid && typeof threadUuid === 'string') {
    thread.fetchMessages(threadUuid);
  }
});

const showForm = computed(() => {
  return !thread.hasConversationStarted && (thread.uuid === null || thread.uuid === undefined);
});
</script>

<template>
  <Suspense>
    <MainLayout :showFooter="showForm">
      <FadeTransition :duration="250" mode="out-in">
        <Stack v-if="showForm" class="prompt-container" :justifyContent="Position.Center" :gap="0">
          <Greeting />
          <LargePrompt />
          <Disclaimer />
        </Stack>
        <Conversation v-else />
      </FadeTransition>
      <FirstLoginPopup />
    </MainLayout>
    <template #fallback> Loading... </template>
  </Suspense>
</template>

<style lang="scss" scoped>
.prompt-container {
  margin-top: auto;
  margin-bottom: auto;

  @include mq(sm) {
    padding-bottom: 20vh;
  }
}
</style>
