<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import { TooltipPosition } from '@schulinck/components/ui/tooltip/Tooltip.types';
import Tooltip from '@schulinck/components/ui/tooltip/Tooltip.vue';
import { type ComponentPublicInstance, computed, onMounted, ref } from 'vue';

import MarkdownContent from '@/components/conversation/MarkdownContent.vue';
import SourceBreadcrumbs from '@/components/conversation/response/source/SourceBreadcrumbs.vue';
import { EventType, SourceVisitedEventData } from '@/models/Event';
import { ContentType, Source } from '@/models/Message';
import { Permission } from '@/models/PermissionSet';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useLocaleTranslator } from '@/translations';

import SourceFeedback from '../feedback/SourceFeedback.vue';

interface Props {
  source: Source;
  focussed?: boolean;
}

const props = defineProps<Props>();

const { translate } = useLocaleTranslator();

const element = ref<ComponentPublicInstance>();
const open = ref(props.focussed);
const sourceVisitedEvent: Event<SourceVisitedEventData> = new Event().setType(EventType.SourceVisited);
const tooltipTitle = computed(() => (open.value ? translate('sources.readLess') : translate('sources.readMore')));

const toggleChunk = () => {
  open.value = !open.value;
};

const sourceClick = () => {
  sourceVisitedEvent.setData({
    title: props.source.title,
    content_type: props.source.content_type,
    content_id: props.source.content_id,
    permalink: props.source.permalink
  });

  dataLayer.push(sourceVisitedEvent);
};
onMounted(() => {
  if (props.focussed && element.value) {
    setTimeout(() => {
      element.value?.$el.scrollIntoView({ behavior: 'smooth', inline: 'nearest', alignToTop: true });
    }, 300);
  }
});
</script>
<template>
  <div>
    <Stack
      ref="element"
      :justifyContent="Position.Between"
      :class="['source-list-item', focussed && 'focussed']"
      :gap="0"
    >
      <div class="header">
        <Cluster :alignItems="Position.Center" :justifyContent="Position.Between" @click.prevent="toggleChunk">
          <Clickable target="_blank" class="title">{{ source.title }}</Clickable>
          <Cluster :alignItems="Position.Center" :gap="0">
            <SourceFeedback v-if="$hasPermission(Permission.SourceFeedback)" :source />
            <Clickable class="chevron">
              <Tooltip :title="tooltipTitle" :position="TooltipPosition.Left">
                <Icon :name="IconNames.ChevronDown" :rotate="open" />
              </Tooltip>
            </Clickable>
          </Cluster>
        </Cluster>
        <SourceBreadcrumbs :breadcrumbs="source.breadcrumbs" :contentType="source.content_type as ContentType" />
      </div>
      <div :class="['chunk-container', open && 'open']">
        <div class="chunk">
          <MarkdownContent :type="'source'" :content="source.content" />
          <Clickable target="_blank" :href="source.permalink" class="source-link" :onClick="sourceClick"
            >{{ translate('sources.goToFullSource') }}<Icon :name="IconNames.ArrowRight" />
          </Clickable>
        </div>
      </div>
    </Stack>
  </div>
</template>

<style scoped lang="scss">
.source-list-item {
  padding: 0.5rem 1rem 1rem 1rem;
  background-color: $wk-white;
  border: solid 1px $wk-gray-tint4;
  transition: all 0.2s;
  height: 100%;

  &.focussed,
  &:hover {
    border: solid 1px $wk-blue-shade1;
    box-shadow: 0 0.25rem 0.75rem 0 rgba($wk-black, 0.1);
  }

  .header {
    cursor: pointer;
    .title {
      color: $wk-blue;
      position: relative;
      font-weight: 500;
      width: 100%;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .chevron {
      cursor: pointer;
      padding: 0.25rem 0.375rem;
      border-radius: 50%;

      &:hover {
        background-color: $wk-blue-tint6;
      }
    }
  }

  .chunk-container {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;

    &.open {
      grid-template-rows: 1fr;
    }
  }

  .chunk {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .source {
      padding: 0.5rem 0 1rem 0;
      overflow: auto;
    }

    .source-link {
      color: $wk-blue;
      text-align: right;
      width: fit-content;
      align-self: end;

      > i {
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        text-decoration: none;

        > i {
          padding-left: 0.25rem;
        }
      }
    }
  }
}
</style>
