import Resource, { HALResourceDataInterface } from '@schulinck/lib/hateoas/resources/Resource';

export enum DomainCode {
  Jeugd = 'jeugd',
  Schuldhulpverlening = 'wgs',
  Inburgering = 'wi',
  Wmo = 'wmo',
  Participatiewet = 'pw',
  Omgevingsrecht = 'or'
}

export const domainNames = {
  [DomainCode.Jeugd]: 'Jeugd',
  [DomainCode.Schuldhulpverlening]: 'Schuldhulpverlening',
  [DomainCode.Inburgering]: 'Inburgering',
  [DomainCode.Wmo]: 'Wmo',
  [DomainCode.Participatiewet]: 'Participatiewet',
  [DomainCode.Omgevingsrecht]: 'Omgevingsrecht',
  ['']: 'Onbekend domein'
};

export const getDomainName = (domain?: DomainCode): string => domainNames[domain || ''];

export interface DomainDataInterface extends HALResourceDataInterface {
  productCode: string;
  name: string;
}

export default class Domain extends Resource {
  public readonly productCode: string;
  public readonly name: string;

  constructor(data: HALResourceDataInterface) {
    super(data);

    this.productCode = data.productCode;
    this.name = data.name;
  }
}
