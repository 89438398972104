import Menu from '@schulinck/components/ui/stacked-menu/models/Menu';
import Resource, { HALResourceDataInterface } from '@schulinck/lib/hateoas/resources/Resource';

export interface ProfileMenuDataInterface extends HALResourceDataInterface {
  readonly username: string;
  readonly userInitials: string | undefined;
  readonly organisationName: string;
}

export default class ProfileMenu extends Resource {
  public readonly username: string;
  public readonly userInitials: string | undefined;
  public readonly organisationName: string;
  public readonly menu: Menu;

  constructor(data: ProfileMenuDataInterface) {
    super(data);

    this.username = data.username;
    this.userInitials = data.userInitials;
    this.organisationName = data.organisationName;
    this.menu = this._getEmbeddedObject('menu', Menu);
  }
}
