import { createRouter, createWebHistory, Router } from 'vue-router';

import AuthService from '@/services/auth/authService';

import routes from './routes';

const router: Router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL)
});

router.beforeEach((to, from, next) => {
  if (!to.meta.public && !AuthService.isAuthenticated()) {
    AuthService.redirectToLogin(to.path);
    next(false);
  } else {
    next();
  }
});

export default router;
