<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';

import { setupDragAndDropHandlers } from '@/services/utils/dragAndDropHandlers';

interface Props {
  inputValue: string;
  placeholder: string;
  minHeight?: string;
  maxHeight?: string;
  onUpdate: (value: string) => void;
  onSubmit: (event: KeyboardEvent) => void;
}

const props = withDefaults(defineProps<Props>(), {
  minHeight: '1.5rem',
  maxHeight: '4.5rem'
});

const inputElement = ref<HTMLTextAreaElement>();
const textAreaHeight = ref<number>(0);
const textAreaCssHeight = computed(() => `${textAreaHeight.value}px`);

const handleInput = async () => {
  props.onUpdate(inputElement.value?.value || '');
  await updateHeight();
};

const updateHeight = async () => {
  textAreaHeight.value = 0;
  await nextTick();
  textAreaHeight.value = inputElement.value?.scrollHeight || 0;
};

onMounted(() => {
  updateHeight();
});

watch(
  () => props.inputValue,
  async () => {
    await updateHeight();
  }
);

setupDragAndDropHandlers(inputElement);
</script>

<template>
  <textarea
    ref="inputElement"
    :value="inputValue"
    class="growing-textarea"
    :placeholder="placeholder"
    autofocus
    @keydown.enter.exact="onSubmit"
    @input="handleInput"
  />
</template>

<style scoped lang="scss">
.growing-textarea {
  font-size: inherit;
  width: 100%;
  resize: none;
  margin-bottom: 0;
  box-sizing: border-box;
  height: clamp(v-bind(minHeight), v-bind(textAreaCssHeight), v-bind(maxHeight));

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    color: $wk-gray;
    font-weight: 300;
  }
}
</style>
