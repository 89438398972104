<script setup lang="ts">
import Select from '@schulinck/components/forms/select/Select.vue';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import TooltipInteractive from '@schulinck/components/ui/tooltip-interactive/TooltipInteractive.vue';
import { computed, ref, watch } from 'vue';

import { DomainDataInterface } from '@/models/Domain';
import { DomainSelectedEventData, EventNoData, EventType } from '@/models/Event';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event as TrackingEvent } from '@/services/tracking/event';
import { useThreadStore } from '@/stores/thread';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

import Counter from './Counter.vue';
import DomainTooltip from './DomainTooltip.vue';
import GrowingTextarea from './GrowingTextarea.vue';

const threadStore = useThreadStore();
const inputValue = ref<string>('');
const userStore = useUserStore();
const domainSelectorReference = ref<HTMLElement>();
const showDomainTooltip = ref(false);
const { translate } = useLocaleTranslator();
const maxPromptLength = 2000;

const availableDomains = computed(() => userStore.userDomains);
const currentDomainCode = computed(() => (userStore.lastChosenDomain ? userStore.lastChosenDomain.productCode : null));
const promptLengthExceeded = computed(() => inputValue.value.length > maxPromptLength);
const preventSubmit = computed(() => inputValue.value.length === 0 || promptLengthExceeded.value);
const availableDomainsOptions = computed(() =>
  availableDomains.value.map((domain: DomainDataInterface) => ({ label: domain.name, value: domain.productCode }))
);

const domainSelectedEvent: TrackingEvent<DomainSelectedEventData> = new TrackingEvent().setType(
  EventType.DomainSelected
);
const promptLengthExceededEvent: TrackingEvent<EventNoData> = new TrackingEvent().setType(
  EventType.PromptLengthExceeded
);

const handleInputChange = (value: string) => (inputValue.value = value);
const hideDomainTooltip = () => (showDomainTooltip.value = false);

const submitPrompt = (event: KeyboardEvent) => {
  event.preventDefault();
  if (!preventSubmit.value) {
    if (!currentDomainCode.value) {
      showDomainTooltip.value = true;
      return;
    }
    threadStore.addQuestion(inputValue.value, currentDomainCode.value);
    inputValue.value = '';
  }
};

const handleDomainChange = (value: string) => {
  userStore.setLastChosenDomainCode(value);
  domainSelectedEvent.setData({ domain_code: value });
  dataLayer.push(domainSelectedEvent);
};

watch(promptLengthExceeded, () => dataLayer.push(promptLengthExceededEvent));
</script>

<template>
  <div class="large-prompt">
    <GrowingTextarea
      :inputValue="inputValue"
      :placeholder="translate('large-prompt.placeholder')"
      :onUpdate="handleInputChange"
      :onSubmit="submitPrompt"
      minHeight="4.5rem"
      maxHeight="40vh"
    />
    <div class="large-prompt-actions">
      <Select
        ref="domainSelectorReference"
        :class="{ 'error-border': showDomainTooltip }"
        :value="currentDomainCode"
        :options="availableDomainsOptions"
        :onUpdate="(value) => handleDomainChange(value as string)"
        :disabled="availableDomains.length <= 1"
        :placeholder="translate('large-prompt.select_product')"
      />
      <div class="right-actions">
        <Counter :count="inputValue.length" :max="maxPromptLength" :exceeded="promptLengthExceeded" />
        <button type="submit" :disabled="preventSubmit" :onclick="submitPrompt">
          <Icon :name="IconNames.ArrowRight" />
        </button>
      </div>
    </div>
    <TooltipInteractive
      v-if="domainSelectorReference && showDomainTooltip"
      :offset="20"
      :onClose="hideDomainTooltip"
      :reference="domainSelectorReference"
    >
      <template #buttons><span></span></template>
      <DomainTooltip :onCancel="hideDomainTooltip" />
    </TooltipInteractive>
  </div>
</template>

<style scoped lang="scss">
.large-prompt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  margin: 1rem 0 0.5rem 0;
  gap: 0.5rem;
  background-color: $wk-white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  outline: solid 1px $wk-gray-tint1;

  &:focus-within {
    outline: solid 2px $wk-black;
  }

  .large-prompt-actions {
    display: flex;
    justify-content: space-between;
    align-items: end;
    font-size: 0.875rem;
    width: 100%;

    :deep(.wk-select) {
      border: 1px solid $wk-gray-tint4;
      padding: 0.5px 0.75rem;
      border-radius: 1rem;
      height: auto;
      font-size: 0.875rem;

      .wk-select-title {
        font-size: 0.875rem;
      }
      .wk-select-icon {
        margin-left: 0.25rem;
      }
    }

    .error-border {
      :deep(.wk-select) {
        border-color: $wk-red;
        background: rgba(246, 177, 182, 0.15);
      }
    }

    :deep(.wk-select-active) {
      background-color: $wk-blue-tint6;

      .wk-select-icon {
        transform: rotate(-180deg);
      }
    }
    :deep(.wk-field-is-disabled) {
      opacity: 1;
      .wk-select-icon {
        display: none;
      }
    }

    .right-actions {
      display: flex;
      align-items: center;

      button[type='submit'] {
        background: $wk-blue;
        color: $wk-white;
        border-radius: 50%;
        width: 33px;
        height: 33px;
        margin-left: 1rem;
        font-size: 1.0625rem;

        &:disabled {
          background: $wk-gray-tint5;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.wk-dropdown {
  margin-top: 0.25rem;
  border-top-width: 1px !important;
  width: auto !important;
}
</style>
