<script setup lang="ts">
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';
import { ComponentPublicInstance, onMounted, ref } from 'vue';

import { MessageInterface } from '@/models/Message';
import { calcNumberOfLinesInElement } from '@/services/utils/calcNumberOfLinesInElement';

interface Props {
  message: MessageInterface;
}
defineProps<Props>();

const questionElement = ref<ComponentPublicInstance>();
const questionSize = ref<TypographyVariants>(TypographyVariants.H1);

onMounted(() => {
  if (calcNumberOfLinesInElement(questionElement.value?.$el) > 4) questionSize.value = TypographyVariants.H4;
  questionElement.value?.$el.scrollIntoView();
});
</script>
<template>
  <Typography ref="questionElement" class="question" :variant="questionSize" noMargin>{{
    message.content.body
  }}</Typography>
</template>

<style scoped lang="scss">
.question {
  white-space: pre-wrap;
  padding-bottom: 1.5rem;
  word-break: break-word;
}
</style>
