<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import ghost from '@/assets/ghost-large.svg';
import MainLayout from '@/components/layout/MainLayout.vue';

interface Props {
  image?: string;
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Error',
  image: ghost
});
</script>

<template>
  <Suspense>
    <MainLayout showFooter>
      <Stack class="wk-a-error">
        <img class="ghost" :src="image" :alt="title" />
        <Stack>
          <Typography :center="true" :variant="TypographyVariants.H1">{{ props.title }}</Typography>
          <slot></slot>
        </Stack>
      </Stack>
    </MainLayout>
  </Suspense>
</template>

<style scoped lang="scss">
img.ghost {
  max-width: 240px;
  margin-bottom: 1rem;
}
.wk-a-error {
  flex: 1;
  align-items: center;
  justify-content: center;
}
</style>
