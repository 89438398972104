export enum NotificationType {
  Error = 'error',
  Success = 'success',
  Info = 'info',
  Warning = 'warning'
}

export interface NotificationMessage {
  id?: string;
  type?: NotificationType;
  message: string;
  title?: string;
}
