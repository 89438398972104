import Auth from '@/components/auth/Auth.vue';
import UnauthorizedView from '@/views/403.vue';
import NotFound from '@/views/404.vue';
import ErrorView from '@/views/500.vue';
import AboutView from '@/views/About.vue';
import CookiePolicyView from '@/views/CookiePolicy.vue';
import HomeView from '@/views/Home.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {}
  },
  {
    path: '/auth',
    name: 'auth',
    component: Auth,
    meta: { public: true }
  },
  {
    path: '/chat/:thread_uuid([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})',
    name: 'conversation',
    component: HomeView,
    meta: {}
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {}
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: CookiePolicyView,
    meta: {}
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    meta: { public: true },
    component: UnauthorizedView
  },
  {
    path: '/error',
    name: 'error',
    meta: { public: true },
    component: ErrorView
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { public: true }
  }
];
