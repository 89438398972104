export default {
  cancel: 'Annuleren',
  submit: 'Verzenden',
  close: 'Sluiten',

  'application.title': 'Schulinck Assistent',

  'auth.initiating': 'Bezig met inloggen...',

  'greeting.morning': 'Goedemorgen',
  'greeting.afternoon': 'Goedemiddag',
  'greeting.evening': 'Goedenavond',
  'greeting.night': 'Goedenacht',

  'large-prompt.placeholder': 'Vul hier je vraag in...',
  'large-prompt.select_product': 'Selecteer je vakgebied',
  'small-prompt.placeholder': 'Heb je nog een aanvulling of vervolgvraag?',

  'greeting.subtitle': 'Schulinck Assistent staat klaar om je snel te helpen met al je juridische vragen',

  'disclaimer.message':
    'Schulinck Assistent is in beta en kan fouten maken. Controleer <strong>altijd</strong> belangrijke informatie.',
  'disclaimer.linkText': 'Meer lezen over de beta.',

  'conversation.meta.loading': 'Laden...',

  'sources.singleSource': 'bron',
  'sources.sources': 'bronnen',
  'sources.readMore': 'Meer Lezen',
  'sources.readLess': 'Minder Lezen',
  'sources.goToFullSource': 'Naar volledige kennisbank pagina',

  'footer.privacy': 'Privacy',
  'footer.cookiePolicy': 'Cookiebeleid',
  'footer.gdpr': 'AVG',
  'footer.disclaimer': 'Auteursrecht & Disclaimer',
  'footer.conditions': 'Algemene voorwaarden',
  'footer.copyright': '© %year% Wolters Kluwer. Alle rechten voorbehouden',

  'contentType.frequently_asked_question': 'Veelgestelde vragen',
  'contentType.guideline': 'Beleid',
  'contentType.municipal_document': 'Gemeentelijk bijlagen',
  'contentType.regulation_element': 'Wetteksten',
  'contentType.thematic_immersion': "Thema's",
  'contentType.verdict': 'Rechtspraak',
  'contentType.verdict_annotation': 'Rechtspraak',
  'contentType.jira_comment': 'Helpdesk',

  'domaintooltip.title': 'Selecteer hier je vakgebied',
  'domaintooltip.info': 'Vul hier je gebruikelijke vakgebied in zodat de assistent je beter begrijpt.',
  'domaintooltip.be_aware': 'Let op: het gekozen vakgebied wordt opgeslagen maar kun je altijd veranderen.',

  '404.title': '404 - Deze pagina bestaat niet',
  '404.p1': 'We konden deze pagina helaas niet vinden. Controleer de link op typefouten of',
  '404.go_to_home': 'ga naar de homepagina',

  '403.title': '403 - Je hebt geen toegang tot deze pagina',
  '500.title': '500 - Er is iets misgegaan, probeer het later opnieuw'
};
