<script setup lang="ts">
import { marked } from 'marked';
import { computed } from 'vue';

interface Props {
  content: string | null;
  type?: string | null;
}

const props = defineProps<Props>();

const content = computed(() => marked(props.content ?? '', { breaks: true }));
</script>

<template>
  <div :class="type" v-html="content" />
</template>

<style lang="scss" scoped>
.source {
  :deep(h1),
  :deep(h2),
  :deep(h3),
  :deep(h4),
  :deep(h5),
  :deep(h6) {
    font-size: 1rem;
    margin-top: 0.1rem;
    margin-bottom: 0.2rem;
  }

  :deep(p),
  :deep(ul),
  :deep(li),
  :deep(ol),
  :deep(table) {
    font-size: 0.875rem;
  }

  :deep(p) {
    margin-bottom: 0.5rem;
  }

  :deep(thead th) {
    font-weight: normal;
  }
}

:deep(h1),
:deep(h2),
:deep(h3),
:deep(h4),
:deep(h5),
:deep(h6) {
  margin-top: 0.375rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

:deep(h1) {
  font-size: calc(1.375rem + 1.5vw);
}

@include mq(lg) {
  :deep(h1) {
    font-size: 2.5rem;
  }
}

:deep(h2) {
  font-size: calc(1.325rem + 0.9vw);
}

@include mq(lg) {
  :deep(h2) {
    font-size: 2rem;
  }
}

:deep(h3) {
  font-size: calc(1.3rem + 0.6vw);
}

@include mq(lg) {
  :deep(h3) {
    font-size: 1.75rem;
  }
}

:deep(h4) {
  font-size: calc(1.275rem + 0.3vw);
}

@include mq(lg) {
  :deep(h4) {
    font-size: 1.5rem;
  }
}

:deep(h5) {
  font-size: 1.25rem;
}

:deep(h6) {
  font-size: 1rem;
}

:deep(p) {
  margin-top: 0;
  margin-bottom: 1rem;
}

:deep(ol),
:deep(ul) {
  padding-left: 2rem;
}

:deep(ol),
:deep(ul) {
  margin-top: 0;
  margin-bottom: 1rem;
}

:deep(ol ol),
:deep(ul ul),
:deep(ol ul),
:deep(ul ol) {
  margin-bottom: 0;
}

:deep(ul) {
  list-style-type: disc;
}

:deep(ol) {
  list-style-type: decimal;
}

:deep(ul ul),
:deep(ol ul) {
  list-style-type: circle;
  margin-left: 15px;
}

:deep(ol ol),
:deep(ul ol) {
  list-style-type: lower-latin;
  margin-left: 15px;
}

:deep(b),
:deep(strong) {
  font-weight: bolder;
}

:deep(small) {
  font-size: 0.875em;
}

:deep(table) {
  caption-side: bottom;
  border-collapse: collapse;
}

:deep(th) {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

:deep(thead),
:deep(tbody),
:deep(tfoot),
:deep(tr),
:deep(td),
:deep(th) {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
</style>
