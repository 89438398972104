export const getPrefixFromFileName = (fileName: string): string => {
  const trimmedFileName = fileName.trim();
  const lastDotIndex = trimmedFileName.lastIndexOf('.');
  const nameWithoutExtension = lastDotIndex > -1 ? trimmedFileName.substring(0, lastDotIndex) : trimmedFileName;
  return nameWithoutExtension.replace(/[_-\s]/g, '.') + '.';
};

export const addPrefixToKeysRecursively = (obj: unknown, prefix: string): unknown => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  return Object.keys(obj).reduce((acc: Record<string, unknown>, key: string) => {
    const value = obj[key];
    const newKey = `${prefix}${key}`;
    acc[newKey] = typeof value === 'object' ? addPrefixToKeysRecursively(value, prefix) : value;
    return acc;
  }, {});
};

export const addPrefixToKeys = (obj: Record<string, unknown>, prefix: string): Record<string, unknown> => {
  return addPrefixToKeysRecursively(obj, prefix);
};
