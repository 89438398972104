import { EmbeddedCollection } from '@schulinck/lib/hateoas/collections/EmbeddedCollection';
import Resource, { HALResourceDataInterface } from '@schulinck/lib/hateoas/resources/Resource';

import Organisation from './Organisation';
import Product from './Product';
import UserDetails from './UserDetails';

export interface UserDataInterface extends HALResourceDataInterface {
  userDetails: UserDetails;
  organisations: Organisation[];
  products: Product[];
}

export default class User extends Resource {
  public readonly userDetails: UserDetails;
  public readonly products: EmbeddedCollection<Product>;
  public readonly organisations: EmbeddedCollection<Organisation>;

  constructor(data: UserDataInterface) {
    super(data);

    this.userDetails = this._getEmbeddedObject('userDetails', UserDetails);
    this.organisations = new EmbeddedCollection<Organisation>(this, 'organisations', Organisation);
    this.products = new EmbeddedCollection<Product>(this, 'products', Product);
  }
}
