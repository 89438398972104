<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import Link from '@schulinck/components/ui/link/Link.vue';
import Smokebreak from '@schulinck/components/ui/smokebreak/Smokebreak.vue';
import { computed, ref } from 'vue';

import { EventState, EventType, SourceClickedEventData, SourceSidebarEventData } from '@/models/Event';
import { Source } from '@/models/Message';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useLocaleTranslator } from '@/translations';

import SourceBlock from './SourceBlock.vue';
import SourceListItem from './SourceListItem.vue';

interface Props {
  sources: Source[];
}

const props = defineProps<Props>();

const { translate } = useLocaleTranslator();
const showSources = ref(false);
const selectedSource = ref(-1);
const firstThreeSources = computed(() => props.sources?.slice(0, 3));

const sourceClickedEvent: Event<SourceClickedEventData> = new Event().setType(EventType.SourceClicked);
const sourceSidebarEvent: Event<SourceSidebarEventData> = new Event().setType(EventType.SourceSidebar);

const openSourcesSidebar = (index: number | null, source?: Source) => {
  showSources.value = true;
  selectedSource.value = index ?? -1;

  if (source !== undefined) {
    sourceClickedEvent.setData({
      title: source.title,
      content_type: source.content_type,
      content_id: source.content_id
    });

    dataLayer.push(sourceClickedEvent);
  }

  sourceSidebarEvent.setData({
    state: EventState.Open
  });

  dataLayer.push(sourceSidebarEvent);
};

const closeSourcesSidebar = () => {
  showSources.value = false;

  sourceSidebarEvent.setData({
    state: EventState.Closed
  });

  dataLayer.push(sourceSidebarEvent);
};

const sourcesLengthSurplus = computed(() =>
  props.sources.length > 3 ? props.sources.length - 3 : props.sources.length
);
const singleOrPluralPostFix = computed(() =>
  sourcesLengthSurplus.value > 1 ? translate('sources.sources') : translate('sources.singleSource')
);

window.addEventListener('keyup', (event) => {
  if (event.key === 'Escape') (document.getElementsByClassName('wk-backdrop')[0] as HTMLElement)?.click();
});
</script>
<template>
  <Cluster class="source-blocks-container" :alignItems="Position.Stretch">
    <SourceBlock
      v-for="(source, index) in firstThreeSources"
      :key="index"
      :source="source"
      @click="openSourcesSidebar(index, source)"
    />
    <Link v-if="sources?.length && sources?.length > 3" class="more-sources" @click="openSourcesSidebar(null)">
      {{ `+${sourcesLengthSurplus} ${singleOrPluralPostFix}` }}
      <Icon :name="IconNames.ArrowRight" />
    </Link>
  </Cluster>

  <Smokebreak
    :show="showSources"
    :title="`${props.sources.length} ${singleOrPluralPostFix}`"
    :onClose="closeSourcesSidebar"
  >
    <Stack :gap="1.25">
      <SourceListItem
        v-for="(source, index) in sources"
        :key="index"
        :source="source"
        :focussed="selectedSource === index"
      />
    </Stack>
  </Smokebreak>
</template>

<style scoped lang="scss">
.source-blocks-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 2rem;

  & > div {
    width: 100%;
  }

  @include mq(xs) {
    grid-template-columns: repeat(2, 174px);
  }

  @include mq(sm) {
    grid-template-columns: repeat(auto-fill, 174px);

    & > div {
      width: 174px;
    }
  }
}

.more-sources {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: $wk-blue-shade1;
  padding: 0.5rem;
  height: 3rem;

  @include mq(xs) {
    height: 5rem;
  }

  > i {
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    text-decoration: none;

    > i {
      padding-left: 0.25rem;
    }
  }
}
</style>
