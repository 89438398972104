<script setup lang="ts">
// TODO: Replace with @schulinck/components version, but that introduces errors....
import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import Icon from '@schulinck/components/ui/icon/Icon.vue';
import CloseIconButton from '@schulinck/components/ui/icon-button/CloseIconButton.vue';
import { onMounted, ref } from 'vue';

import { NotificationMessage, NotificationType } from '@/models/Notification';
import { useLocaleTranslator } from '@/translations';

interface Props {
  message: NotificationMessage;
  onClose: () => void;
}

defineProps<Props>();

const notification = ref<HTMLElement>();
const { translate } = useLocaleTranslator();

onMounted(() => {
  notification.value?.scrollIntoView({
    block: 'center',
    behavior: 'smooth'
  });
});

const icons = {
  [NotificationType.Success]: IconNames.CheckCircle,
  [NotificationType.Info]: IconNames.Info,
  [NotificationType.Warning]: IconNames.Caution,
  [NotificationType.Error]: IconNames.StopSign
};
</script>

<template>
  <div ref="notification" :class="['wk-notification', `wk-notification-${message.type}`]" role="alert">
    <div class="wk-notification-icon">
      <Icon :name="icons[message.type]" filled />
    </div>
    <div class="wk-notification-content">
      <p class="wk-notification-title">{{ message.title || translate(message.type) }}</p>
      <p class="wk-notification-message">{{ message.message }}</p>
    </div>
    <CloseIconButton :onClick="onClose" />
  </div>
</template>

<style scoped lang="scss">
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.wk-notification {
  animation: slideInFromRight 0.4s ease-out forwards;
  position: fixed;
  right: 2rem;
  top: 5rem;
  display: flex;
  padding: 1rem;
  align-items: baseline;
  gap: 1rem;
  flex: 1 0 0;
  z-index: map-get($zIndexes, 'notifications');

  @include border;
  border-left-width: 4px;
  background: $wk-white;

  &.wk-notification-success {
    border-color: $wk-green;

    .wk-notification-icon {
      color: $wk-green;
    }
  }

  &.wk-notification-info {
    border-color: $wk-blue;

    .wk-notification-icon {
      color: $wk-blue;
    }
  }

  &.wk-notification-warning {
    border-color: $wk-orange;

    .wk-notification-icon {
      color: $wk-orange;
    }
  }

  &.wk-notification-error {
    border-color: $wk-red;

    .wk-notification-icon {
      color: $wk-red;
    }
  }
}

.wk-notification-icon {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: $wk-blue;

  .wk-icon {
    margin: 0;
    width: 1rem;
    height: 1rem;
  }
}

.wk-notification-content {
  flex-grow: 1;
  font-size: 0.875rem;

  .wk-notification-title {
    font-weight: 500;
    line-height: 1.29;
    margin-bottom: 0.25rem;
    margin-right: 2rem;
  }

  .wk-notification-message {
    line-height: 1.5;
    margin-bottom: 0;
  }
}
</style>
