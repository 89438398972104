import { IconNames } from '@schulinck/components/ui/icon/Icon.types';

import { ActionData, ActionInterface } from '@/models/Action';

export class Action implements ActionInterface {
  private readonly label: string;
  private readonly onClick: (() => void) | undefined;
  private readonly url: string | undefined;
  private readonly icon: IconNames | undefined;

  constructor(data: ActionData) {
    this.label = data.label;
    this.url = data.url;
    this.onClick = data.onClick;
    this.icon = data.icon;
  }

  public getLabel(): string {
    return this.label;
  }

  public getUrl(): string | undefined {
    return this.url;
  }

  public getOnClick(): (() => void) | undefined {
    return this.onClick;
  }

  public getIcon(): IconNames | undefined {
    return this.icon;
  }
}
