import { EventData } from '@/models/Event';
import { useEventMetaDataStore } from '@/stores/tracking/eventMetaData';

export class Event<T> {
  public type: string = '';
  public data: Partial<T> = {};

  public setType(type: string): this {
    this.type = type;
    return this;
  }

  public getName(): string {
    return this.type;
  }

  public setData(data: Partial<T>): this {
    this.data = { ...this.data, ...data };
    return this;
  }

  public getData(): Partial<T> & EventData {
    return {
      ...this.data,
      meta: useEventMetaDataStore().getData
    };
  }
}
