import { jwtDecode } from 'jwt-decode';

export interface DecodedToken {
  iat: number;
  exp: number;
  uid: string;
  is_authorized: boolean;
  refresh: boolean;
}

export function jwtDecoder(token: string): DecodedToken | null {
  try {
    const decoded = jwtDecode(token);

    if (decoded) {
      return decoded as DecodedToken;
    }

    return null;
  } catch (error) {
    console.error('Error decoding token:', error);

    return null;
  }
}
