import { EventData } from '@/models/Event';
import { Event } from '@/services/tracking/event';

class DataLayer {
  public push(event: Event<unknown>): void {
    const eventData: Partial<unknown> & EventData = event.getData();
    if (eventData.meta) {
      eventData.meta = JSON.stringify(eventData.meta);
    }

    window.dataLayer?.push({
      event: event.getName(),
      data: eventData
    });
  }
}

export const dataLayer: DataLayer = new DataLayer();
