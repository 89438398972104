<script setup lang="ts">
import List from '@schulinck/components/ui/list/List.vue';
import ListItem from '@schulinck/components/ui/list/ListItem.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import MainLayout from '@/components/layout/MainLayout.vue';
import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();
</script>

<template>
  <Suspense>
    <MainLayout showFooter>
      <Typography :variant="TypographyVariants.H1">{{ translate('cookie.title') }}</Typography>
      <Typography :variant="TypographyVariants.Paragraph"><div v-html="translate('cookie.p1')"></div></Typography>
      <Typography :variant="TypographyVariants.H2">{{ translate('cookie.cookie_types_title') }}</Typography>
      <List>
        <ListItem>{{ translate('cookie.type1') }}</ListItem>
        <br />
        <ListItem>{{ translate('cookie.type2') }}</ListItem>
        <br />
        <ListItem>{{ translate('cookie.type3') }}</ListItem>
        <br />
        <ListItem>{{ translate('cookie.type4') }}</ListItem>
      </List>
      <Typography :variant="TypographyVariants.Small"> {{ translate('cookie.more_info') }}</Typography>
    </MainLayout>
  </Suspense>
</template>

<style scoped lang="scss">
ul {
  list-style-position: outside;
}
</style>
