import { AxiosRequestConfig } from 'axios';

import router from '@/router';
import { kb_api } from '@/services/api/api';
import { isTokenExpired } from '@/services/auth/jwt/validator';
import { useUserStore } from '@/stores/user';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  refreshTokenRequest?: boolean;
}
interface ApiRefreshResponse {
  JWT?: {
    access?: string;
    refresh?: string;
  };
}

class AuthService {
  private isAuth = false;
  private accessToken: string | null = null;
  private refreshToken: string | null = null;

  private isRefreshing = false;
  private refreshTokenPromise: Promise<string | null> | null = null;

  public isAuthenticated(): boolean {
    return !!this.getAccessToken();
  }

  async refreshAccessToken(destinationUrl: string | null = null): Promise<string | null> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenPromise = new Promise<string | null>((resolve, reject) => {
        const refreshToken = this.getRefreshToken();

        if (!refreshToken || isTokenExpired(refreshToken)) {
          this.redirectToLogin(destinationUrl);
          return;
        }

        kb_api
          .get('auth/refresh-access-token', {
            headers: {
              'X-Authorization-Refresh-Token': refreshToken
            },
            refreshTokenRequest: true
          } as CustomAxiosRequestConfig)
          .then(async ({ data }: { data: ApiRefreshResponse }) => {
            if (data?.JWT?.refresh) {
              this.storeRefreshToken(data.JWT.refresh);
            }

            if (data?.JWT?.access) {
              this.storeAccessToken(data.JWT.access);

              this.isRefreshing = false;
              this.refreshTokenPromise = null;

              await useUserStore().init();

              resolve(data.JWT.access);

              return;
            }

            this.redirectToLogin(destinationUrl);
          })
          .catch((error) => {
            this.isRefreshing = false;
            this.refreshTokenPromise = null;

            reject(error);
          });
      });
    }

    return this.refreshTokenPromise;
  }

  public redirectToLogin(destinationUrl: string | null = null): void {
    if (destinationUrl !== null) {
      this.setDestinationUrl(destinationUrl);
    }

    window.location.href = import.meta.env.VITE_KB_LOGIN_URL;
  }

  public logout(): void {
    this.removeTokens();
    this.isAuth = false;
  }

  public getDestinationUrl(): string | null {
    return localStorage.getItem('destinationUrl');
  }

  public setDestinationUrl(destinationUrl: string): void {
    const route = router.resolve(destinationUrl);

    if (route.matched.length > 0) {
      localStorage.setItem('destinationUrl', route.path);
    }
  }

  public removeDestinationUrl(): void {
    localStorage.removeItem('destinationUrl');
  }

  /**
   * Below functions determine storage of JWT Tokens
   */
  public getAccessToken(): string | null {
    return this.accessToken;
  }

  public storeAccessToken(token: string): void {
    this.accessToken = token;
  }

  public getRefreshToken(): string | null {
    return this.refreshToken;
  }

  public storeRefreshToken(token: string): void {
    this.refreshToken = token;
  }

  public removeTokens(): void {
    this.accessToken = null;
    this.refreshToken = null;
  }
}

export default new AuthService();
