export enum ThumbButtonsState {
  Up = 'up',
  Down = 'down',
  None = 'none'
}

export enum ThumbFeedbackType {
  Answer = 'answer',
  Source = 'source'
}

export const FeedbackValue: { [key in ThumbButtonsState]: string } = {
  [ThumbButtonsState.Up]: 'good',
  [ThumbButtonsState.Down]: 'bad',
  [ThumbButtonsState.None]: 'neutral'
};

export interface FeedbackFormValues {
  [key: string]: FormDataEntryValue;
}
