import { defineStore, StoreDefinition } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

import { TrackingFilter } from '@/models/TrackingFilter';
import { useUserStore } from '@/stores/user';

export const useFilterStore: StoreDefinition = defineStore('trackingFilter', () => {
  const userStore = useUserStore();

  const organisation: ComputedRef<string | null> = computed<string | null>(() => userStore.selectedOrganisation?.name);
  const jobArea: ComputedRef<string | null> = computed<string | null>(() => userStore.userDetails?.jobArea);
  const product: Ref<string> = ref<string>(import.meta.env.VITE_APP_HOST);
  const role: ComputedRef<string | null> = computed<string | null>(() => userStore.selectedOrganisation?.role);
  const job: ComputedRef<string | null> = computed<string | null>(() => userStore.userDetails?.job);

  const getFilters: ComputedRef<TrackingFilter> = computed<TrackingFilter>(() => ({
    user_organisatie: organisation.value,
    user_vakgebied: jobArea.value,
    user_product: product.value,
    user_rol: role.value,
    user_functie: job.value
  }));

  return {
    organisation,
    jobArea,
    product,
    role,
    job,
    getFilters
  };
});
