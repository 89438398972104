import { defineStore, StoreDefinition } from 'pinia';
import { Ref, ref } from 'vue';

import { NotificationMessage, NotificationType } from '@/models/Notification';

export const useNotificationStore: StoreDefinition = defineStore('notification', () => {
  const message: Ref<NotificationMessage> = ref<NotificationMessage>({
    type: undefined,
    title: undefined,
    message: ''
  });
  const visible: Ref<boolean> = ref<boolean>(false);
  const duration: Ref<number> = ref<number>(5000);

  const showNotification = (type: NotificationType, title: string, msg: string, dur: number = 5000): void => {
    message.value = { type, title, message: msg };
    visible.value = true;
    duration.value = dur;

    setTimeout(() => hideNotification(), duration.value);
  };

  const hideNotification = (): void => {
    visible.value = false;
  };

  return {
    message,
    visible,
    duration,
    showNotification,
    hideNotification
  };
});
