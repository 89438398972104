<script setup lang="ts">
import SkeletonPreview from '@schulinck/lib/skeleton-preview/views/SkeletonPreview.vue';
import { computed, ref } from 'vue';

import Content from '@/components/conversation/response/message/Content.vue';
import { MessageInterface, Role, Source } from '@/models/Message';
import { Permission } from '@/models/PermissionSet';
import FadeTransition from '@/transitions/FadeTransition.vue';
import { useLocaleTranslator } from '@/translations';

import AnimatedWKIcon from './AnimatedWKIcon.vue';
import ResponseFeedback from './feedback/ResponseFeedback.vue';
import Sources from './source/Sources.vue';

interface Props {
  message: MessageInterface;
}

const props = defineProps<Props>();
const { translate } = useLocaleTranslator();

const showSmallSkeleton = ref(true);
const responseElement = ref<HTMLElement>();

setTimeout(() => (showSmallSkeleton.value = false), 500);
const sources = computed(() => (props.message.sources as Source[]) || []);
const responseReady = computed(() => undefined !== props.message.content?.body && Array.isArray(sources.value));
const responseTypeClassName = computed(
  () => props.message.type && props.message.role === Role.System && `wk-response-${props.message.type}`
);
</script>

<template>
  <div
    ref="responseElement"
    :class="['response-component', message.role === Role.System && 'system', responseTypeClassName]"
  >
    <AnimatedWKIcon class="ai-icon" :loading="message.loading" />
    <FadeTransition :duration="300" mode="out-in">
      <span v-if="!responseReady"
        ><SkeletonPreview :class="[showSmallSkeleton && 'small']" skeleton="table_of_contents"
      /></span>
      <div v-else class="response-content">
        <Content v-if="message.content" :content="message.content" />
        <Sources v-if="sources.length > 0" :sources="sources" />
        <ResponseFeedback
          v-if="$hasPermission(Permission.AnswerFeedback) && message.role === Role.AI && sources.length > 0"
          :message
        >
          {{ translate('feedback.response.label') }}
        </ResponseFeedback>
      </div>
    </FadeTransition>
  </div>
</template>

<style scoped lang="scss">
.response-component {
  font-size: 1rem;
  line-height: 1.75rem;
  position: relative;

  &.system {
    @include border;
    border-left: none;
    padding: 1rem 1rem 1rem 0;

    .ai-icon {
      top: 1rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: -1px;
      left: calc(-28px - 2rem);
      width: calc(28px + 2rem);
      height: calc(100% + 2px);
      @include border;
      border-left-width: 4px;
      border-color: inherit;
      border-right-width: 0;
      background: inherit;
    }

    &.wk-response-success {
      border-color: $wk-green;
      background: rgba($wk-green-tint3, 0.15);
    }

    &.wk-response-info {
      border-color: $wk-blue;
      background: rgba($wk-blue-tint6, 0.15);
    }

    &.wk-response-warning {
      border-color: $wk-orange;
      background: rgba($wk-orange-tint3, 0.15);
    }

    &.wk-response-error {
      border-color: $wk-red;
      background: rgba($wk-red-tint3, 0.15);
    }
  }

  .ai-icon {
    position: absolute;
    left: calc(-28px - 1rem);
    top: 0;
    height: 28px;
    width: 28px;
    overflow: visible;
  }

  :deep(.skeleton-preview) {
    margin-bottom: 1rem;
    transition: transform 750ms;
    padding-top: calc(0.5rem + 1px); // fuck Frank...

    &.small {
      transform: translateX(-50%) scaleX(0);
    }

    svg {
      animation-delay: 1s;
      g {
        fill: $wk-gray-tint3;
        rect {
          clip-path: inset(0% round 1rem);
        }
      }
    }
  }
}
</style>
