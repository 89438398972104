import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import MenuItem from '@schulinck/components/ui/stacked-menu/models/MenuItem';

import { useLocaleTranslator } from '@/translations';

const { translate } = useLocaleTranslator();

export const menuItemsConfig: Record<string, Partial<MenuItem>> = {
  myOrganisation: {
    icon: IconNames.Briefcase
  },
  mySubscriptions: {
    icon: IconNames.Archive,
    title: translate('profileMenu.menu_item.my_subscriptions.title')
  },
  logout: {
    icon: IconNames.Logout,
    title: translate('profileMenu.menu_item.logout.title')
  }
};
