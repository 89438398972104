import { IconNames } from '@schulinck/components/ui/icon/Icon.types';

import { ContentType } from '@/models/Message';

export interface Breadcrumb {
  title: string;
  permalink: string;
}

export const breadcrumbsIcons: Record<ContentType, IconNames> = {
  [ContentType.Guideline]: IconNames.Municipality,
  [ContentType.MunicipalDocument]: IconNames.Municipality,
  [ContentType.RegulationElement]: IconNames.Scale,
  [ContentType.ThematicImmersion]: IconNames.Book,
  [ContentType.Verdict]: IconNames.Gavel,
  [ContentType.VerdictAnnotation]: IconNames.Gavel,
  [ContentType.FrequentlyAskedQuestion]: IconNames.Dialogue
};
