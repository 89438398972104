<script setup lang="ts">
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import notFound from '@/assets/search-medium.svg';

interface Props {
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Not Found'
});
</script>
<template>
  <Stack class="wk-not-found">
    <img class="not-found" :src="notFound" :alt="title" />
    <Stack>
      <Typography :center="true" :variant="TypographyVariants.H4">{{ props.title }}</Typography>
      <slot></slot>
    </Stack>
  </Stack>
</template>
