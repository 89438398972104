export default {
  'filter.placeholder': 'Zoeken in je historie...',
  'filter.noResults': 'Geen resultaten gevonden',
  'headers.today': 'Vandaag',
  'headers.yesterday': 'Gisteren',

  'newThread.title': 'Nieuwe opdracht',

  'contact.label': 'Contact met Schulinck'
};
