import { jwtDecoder } from './decoder';

export function isTokenExpired(token: string): boolean {
  const decoded = jwtDecoder(token);

  if (!decoded || !decoded.exp) {
    return true;
  }

  const margin = 120 * 1000; // Expire token 2 minutes early to ensure valid token for long API call
  const expiry = decoded.exp * 1000;

  return expiry <= Date.now() + margin;
}
