<script setup lang="ts">
import Clickable from '@schulinck/components/ui/clickable/Clickable.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import Error from '@/components/layout/Error.vue';
import router from '@/router';
import { useLocaleTranslator } from '@/translations';

const goHome = () => {
  router.push({ name: 'home' });
};
const { translate } = useLocaleTranslator();
</script>

<template>
  <Error :title="translate('404.title')">
    <Typography :center="true" :variant="TypographyVariants.Paragraph">
      {{ translate('404.p1') }}
      <Clickable ref="goHomeRef" class="link" :onClick="goHome">{{ translate('404.go_to_home') }}</Clickable
      >.
    </Typography>
  </Error>
</template>
<style scoped lang="scss">
.link {
  color: $wk-blue;
}
</style>
