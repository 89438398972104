import { useLocaleTranslator } from '@/translations';
const { translate } = useLocaleTranslator();

const isInRange = (x: number, min: number, max: number) => {
  return x >= min && x < max;
};

export const getTimeOfDayGreeting = () => {
  const currentHour = new Date().getHours();
  if (isInRange(currentHour, 5, 12)) return translate('greeting.morning');
  if (isInRange(currentHour, 12, 18)) return translate('greeting.afternoon');
  if (isInRange(currentHour, 18, 24)) return translate('greeting.evening');
  if (isInRange(currentHour, 0, 5)) return translate('greeting.night');
};
