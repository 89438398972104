import Resource from '@schulinck/lib/hateoas/resources/Resource';

export interface ProductDataInterface {
  code: string;
  title: string;
  url: string;
}

export default class Product extends Resource {
  public readonly code: string;
  public readonly title: string;
  public readonly url: string;

  constructor(data: ProductDataInterface) {
    super(data);

    this.code = data.code;
    this.title = data.title;
    this.url = data.url;
  }
}
