<script setup lang="ts">
import Cluster from '@schulinck/components/layout/cluster/Cluster.vue';
import Stack from '@schulinck/components/layout/stack/Stack.vue';
import { Position } from '@schulinck/components/theme/position';
import { ButtonTypes } from '@schulinck/components/ui/button/Button.types';
import Button from '@schulinck/components/ui/button/Button.vue';
import { TypographyVariants } from '@schulinck/components/ui/typography/Typography.types';
import Typography from '@schulinck/components/ui/typography/Typography.vue';

import { useLocaleTranslator } from '@/translations';

interface Props {
  onCancel: () => void;
}

defineProps<Props>();

const { translate } = useLocaleTranslator();
</script>

<template>
  <Stack class="domain-tooltip">
    <Typography :variant="TypographyVariants.H3" noMargin>{{ translate('domaintooltip.title') }}</Typography>

    <Typography :variant="TypographyVariants.Paragraph">
      {{ translate('domaintooltip.info') }}
    </Typography>

    <Typography :variant="TypographyVariants.Paragraph">
      <em>{{ translate('domaintooltip.be_aware') }}</em>
    </Typography>

    <Cluster :alignItems="Position.End" :justifyContent="Position.End">
      <Button :type="ButtonTypes.Submit" :onClick="onCancel">
        {{ translate('close') }}
      </Button>
    </Cluster>
  </Stack>
</template>

<style scoped lang="scss">
.domain-tooltip {
  .wk-typography-h4 {
    font-size: 0.875rem;
  }
}
</style>
