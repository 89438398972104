import { IconNames } from '@schulinck/components/ui/icon/Icon.types';
import axios, { AxiosError, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';

import { ErrorReturnType } from '@/models/Error';
import { ApiErrorEventData, EventType } from '@/models/Event';
import { MessageType, Role } from '@/models/Message';
import { NotificationType } from '@/models/Notification';
import Product from '@/models/Product';
import router from '@/router';
import { Action } from '@/services/action/action';
import AuthService from '@/services/auth/authService';
import { isTokenExpired } from '@/services/auth/jwt/validator';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useNotificationStore } from '@/stores/notification';
import { useUserStore } from '@/stores/user';
import { useLocaleTranslator } from '@/translations';

const apiErrorEvent: Event<ApiErrorEventData> = new Event().setType(EventType.ApiError);
const { translate } = useLocaleTranslator();

interface Config extends InternalAxiosRequestConfig {
  headers: AxiosRequestHeaders & {
    'X-Authorization-Refresh-Token'?: string;
    'X-Authorization-Service'?: string;
  };
  url: string;
  refreshTokenRequest?: boolean;
}

const kb_api = axios.create({
  baseURL: import.meta.env.VITE_KB_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const sa_api = axios.create({
  baseURL: import.meta.env.VITE_SA_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

kb_api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const extendedConfig = config as Config;

    let token = AuthService.getAccessToken();

    if (!extendedConfig.refreshTokenRequest && token && isTokenExpired(token)) {
      token = await AuthService.refreshAccessToken();
    }

    if (token) {
      Object.assign(extendedConfig.headers, {
        ...extendedConfig.headers,
        'X-Authorization-Token': token,
        'X-Authorization-Service': import.meta.env.VITE_KB_API_AUTH_SERVICE
      });
    }

    return extendedConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

kb_api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await AuthService.refreshAccessToken();
        originalRequest.headers = {
          ...originalRequest.headers,
          'X-Authorization-Token': newAccessToken,
          'X-Authorization-Service': import.meta.env.VITE_KB_API_AUTH_SERVICE
        };

        return kb_api(originalRequest);
      } catch (refreshError) {
        return Promise.reject(error);
      }
    }

    console.error('Error (kb_api):', error.response);

    return Promise.reject(error);
  }
);

sa_api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    let token = AuthService.getAccessToken();

    if (token && isTokenExpired(token)) {
      token = await AuthService.refreshAccessToken();
    }

    const permissionsToken = useUserStore().selectedOrganisation?.permissionsToken;
    const organisationId = useUserStore().selectedOrganisation?.id;

    if (permissionsToken && organisationId) {
      Object.assign(config.headers, {
        ...config.headers,
        'X-Permissions-Token': permissionsToken,
        'X-Organisation-Id': organisationId
      });
    }

    if (token) {
      Object.assign(config.headers, {
        ...config.headers,
        'X-Authorization-Token': token
      });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

sa_api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await AuthService.refreshAccessToken(originalRequest.url);
        originalRequest.headers = {
          ...originalRequest.headers,
          'X-Authorization-Token': newAccessToken
        };

        return sa_api(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);

        return Promise.reject(error);
      }
    }

    console.error('Error (sa_api):', error);

    return Promise.reject(error);
  }
);

const getHelpdeskUrl = (): string => {
  const userStore = useUserStore();
  const product: Product | undefined = userStore.userProducts.find(
    (product: Product): boolean => product?.code === userStore.lastChosenDomain?.productCode
  );
  return product ? `${product.url}/helpdesk/melding-maken` : '';
};

const handleApiError = (
  error: AxiosError,
  type: ErrorReturnType
): {
  trace_id: null;
  domain: null;
  thread_uuid: null;
  messages: unknown[];
  message: {
    role: Role;
    sources: null;
    loading: boolean;
    type: MessageType.Error;
    content: { body: string; actions: Action[] };
  };
} | null => {
  apiErrorEvent.setData({ error: error.response?.status || 'unknown' });

  dataLayer.push(apiErrorEvent);

  if (error.response?.status === 404) router.push({ name: 'NotFound' });

  if (type === ErrorReturnType.Message) {
    return {
      thread_uuid: null,
      trace_id: null,
      message: {
        role: Role.System,
        content: {
          body: translate(`error.${error.response?.status || 'unknown'}`),
          actions: [
            new Action({
              label: translate('action.helpdesk'),
              url: getHelpdeskUrl(),
              icon: IconNames.Mail
            })
          ]
        },
        sources: null,
        loading: false,
        type: MessageType.Error
      },
      messages: [],
      domain: null
    };
  } else if (type === ErrorReturnType.Notification) {
    useNotificationStore().showNotification(
      NotificationType.Error,
      translate('error.title'),
      translate(`error.${error.response?.status || 'unknown'}`),
      2500
    );
    return null;
  } else {
    return null;
  }
};

export { handleApiError, kb_api, sa_api };
