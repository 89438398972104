import { AxiosError } from 'axios';
import { defineStore, StoreDefinition } from 'pinia';
import { computed, ComputedRef, Ref, ref } from 'vue';

import { DomainCode } from '@/models/Domain';
import { ErrorMessage, ErrorReturnType } from '@/models/Error';
import { DurationCustomerRequestEventData, EventType, QuestionAskedEventData } from '@/models/Event';
import { MessageApiInterface, MessageDataInterface, MessageInterface, Role } from '@/models/Message';
import { QuestionType } from '@/models/Question';
import router from '@/router';
import { handleApiError, sa_api } from '@/services/api/api';
import { dataLayer } from '@/services/tracking/dataLayer';
import { Event } from '@/services/tracking/event';
import { useThreadsStore } from '@/stores/threads';
import { useEventMetaDataStore } from '@/stores/tracking/eventMetaData';
import { useUserStore } from '@/stores/user';

const questionAskedEvent: Event<QuestionAskedEventData> = new Event().setType(EventType.QuestionAsked);
const durationCustomerRequestEvent: Event<DurationCustomerRequestEventData> = new Event().setType(
  EventType.DurationCustomerRequest
);

export const useThreadStore: StoreDefinition = defineStore('thread', () => {
  const uuid: Ref<string | null> = ref(null);
  const traceId: Ref<string | null> = ref(null);
  const created_at: Ref<number | null> = ref(null);
  const domain: Ref<DomainCode | null> = ref(null);
  const messages: Ref<MessageInterface[]> = ref([]);
  const readOnly: Ref<boolean> = ref(false);
  const loading: Ref<boolean> = ref(false);

  const hasConversationStarted: ComputedRef<boolean> = computed((): boolean => messages.value.length > 0);
  const hasAssistantResponded: ComputedRef<boolean> = computed(
    (): boolean => messages.value.length > 1 && !messages.value[1].loading
  );

  const eventMetaData = useEventMetaDataStore();

  const addQuestion = async (question: string, domainCode: DomainCode | null): Promise<void> => {
    if (!domain.value) domain.value = domainCode;
    if (!created_at.value) created_at.value = Math.floor(new Date().getTime() / 1000);

    const message: MessageInterface = {
      role: Role.Human,
      content: { body: question },
      sources: null,
      loading: false,
      trace_id: ''
    };
    messages.value.push(message);

    const questionCount: number = messages.value.filter(
      (message: MessageInterface): boolean => message.role === Role.Human
    ).length;
    questionAskedEvent.setData({
      type: questionCount > 1 ? QuestionType.FollowUp : QuestionType.Initial,
      count: questionCount
    });
    dataLayer.push(questionAskedEvent);

    await addAssistantResponse(question);
  };

  const addAssistantResponse = async (question: string): Promise<void> => {
    loading.value = true;
    const threadsStore = useThreadsStore();
    const userStore = useUserStore();
    const startTime: number = performance.now();
    messages.value.push({
      role: Role.AI,
      content: null,
      sources: null,
      loading: true,
      trace_id: ''
    });
    const index: number = messages.value.length - 1;
    const input: { question: string; domain: DomainCode | null; thread_uuid: string | null } = {
      question: question,
      domain: domain.value,
      thread_uuid: uuid.value || null,
      organisation_name: userStore.selectedOrganisation.name,
      job: userStore.userDetails.job
    };

    const response = await sa_api
      .post('/chat/invoke', { input: input })
      .then(({ data: { output } }) => {
        const message: MessageDataInterface = mapApiResponseToMessageFormat(output.message);
        return {
          thread_uuid: output.thread_uuid,
          trace_id: output.message.trace_id,
          message: message
        };
      })
      .catch((error: AxiosError) => handleApiError(error, ErrorReturnType.Message) as ErrorMessage)
      .finally(() => {
        const endTime: number = performance.now();
        const duration: number = endTime - startTime;
        durationCustomerRequestEvent.setData({ duration_ms: duration });
        dataLayer.push(durationCustomerRequestEvent);
        messages.value[index].loading = loading.value = false;
      });

    traceId.value = response.trace_id;

    const newUpdateAt = Math.floor(Date.now() / 1000);
    if (uuid.value === null && response.thread_uuid !== null) {
      uuid.value = response.thread_uuid;
      eventMetaData.add({ thread_uuid: uuid.value });
      threadsStore.addThread({
        title: messages.value[0].content?.body,
        thread_uuid: response.thread_uuid,
        updated_at: newUpdateAt
      });
      await router.push({ path: `/chat/${uuid.value}` });
    } else {
      threadsStore.updateThread(uuid.value, newUpdateAt);
    }
    messages.value[index] = { ...messages.value[index], ...response.message };
  };

  const fetchMessages = async (threadUuid: string): Promise<void> => {
    loading.value = true;
    uuid.value = threadUuid;
    eventMetaData.add({ thread_uuid: threadUuid });

    const response = await sa_api
      .post('/chat/messages', { input: { thread_uuid: threadUuid } })
      .then(({ data: { output } }) => {
        const messages: MessageDataInterface[] = output.messages.map(mapApiResponseToMessageFormat);
        return {
          messages: messages,
          domain: output.domain as DomainCode,
          readOnly: output.read_only
        };
      })
      .catch((error: AxiosError) => handleApiError(error, ErrorReturnType.Message) as ErrorMessage)
      .finally(() => (loading.value = false));

    for (const message of response.messages) messages.value.push({ ...message, loading: false });

    created_at.value = messages.value[0]?.created_at || null;
    domain.value = response.domain;
    readOnly.value = response.readOnly;
  };

  const mapApiResponseToMessageFormat = (message: MessageApiInterface): MessageDataInterface => {
    return {
      ...message,
      content: {
        body: message.content,
        actions: []
      }
    };
  };

  const resetThread = (): void => {
    uuid.value = null;
    created_at.value = null;
    domain.value = null;
    messages.value = [];
    readOnly.value = false;
    loading.value = false;
  };

  const openThread = async (threadUuid: string): Promise<void> => {
    loading.value = true;
    resetThread();
    await router.push({ path: `/chat/${threadUuid}` });
    await fetchMessages(threadUuid);
  };

  return {
    uuid,
    traceId,
    created_at,
    domain,
    messages,
    readOnly,
    loading,
    hasConversationStarted,
    hasAssistantResponded,
    addQuestion,
    fetchMessages,
    resetThread,
    openThread
  };
});
